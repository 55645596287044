import React, { useState, useEffect } from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  makeStyles,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Divider,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Card,
  CardContent,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";

import DialogBox from "../../Components/Modal/DialogBox";
import FileBase64 from "react-file-base64";

import IconButton from "@material-ui/core/IconButton";
import { Add as AddIcon, Update, Edit, Delete } from "@material-ui/icons";

import ProgressBar from "../../Components/ProgessBar";
import "./style.css";

const useStyles = makeStyles({
  box: {
    maxHeight: "80vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "primary",
      outline: "1px solid slategrey",
    },
  },
  table: {
    minWidth: 150,
  },
  image: {
    maxWidth: "60%",
    "&:hover": {
      padding: "22px",
      // opacity: 0.8,
    },
  },
  btn: {
    padding: 20,
    backgroundColor: "#333",
    color: "#fff",
  },
  getcol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
  imageFull: {
    width: "100%",
  },
});

export default function SubTaskTable(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelImageUrl, setModelImageUrl] = useState("");
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleShowDialog = url => {
    if (!isModalOpen) setModelImageUrl(url);
    setIsModalOpen(!isModalOpen ? true : false);
  };

  return (
    <>
      <DialogBox
        handleShowDialog={handleShowDialog}
        isModalOpen={isModalOpen}
        modelImageUrl={modelImageUrl}
      />
      <Accordion expanded={true}>
        <AccordionSummary
          style={{ backgroundColor: "#fff" }}
          aria-controls="panel1d-content"
        >
          <Grid container alignItems="center">
            <Typography variant="h6">
              {props.subTaskData != null ? props.subTaskData.name : ""}
            </Typography>
          </Grid>

          {props.isAddVisible && (
            <Grid container justify="flex-end">
              <Button
                className="m-2"
                color="primary"
                onClick={props.handleModalOpen}
                startIcon={<Edit />}
              >
                Edit
              </Button>
            </Grid>)}
        </AccordionSummary>
        <AccordionDetails style={{ display: "inline" }}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            style={{ padding: 20, width: "80%", margin: "auto" }}
          >
            <Slider
              key={`slider-${props.subTaskPercentage}`}
              aria-label="Temperature"
              defaultValue={props.subTaskPercentage}
              disabled={!props.isAddVisible}
              valueLabelDisplay="auto"
              step={10}
              marks
              min={0}
              max={100}
              onChange={props.addPercentage}
            />
            {props.isAddVisible && (
              <>
                <Button color="primary" startIcon={<Update />}>
                  <FileBase64
                    multiple={false}
                    className="btn"
                    value={props.subTaskPanaroma}
                    onDone={file => {
                      // files.forEach(ele => ele.base64);
                      props.handlePanaroma(file.base64);
                    }}
                  />
                </Button>
                <Button
                  className="m-2"
                  color="primary"
                  onClick={props.updateSubTask}
                  startIcon={<Update />}
                >
                  Update
                </Button></>
            )}

            {/* <ProgressBar value={props.subTaskPercentage} /> */}
          </Grid>

          <Divider variant="middle" />
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            style={{
              marginTop: 20,
              padding: 20,
            }}
          >
            {/* <Grid item lg={6}>
              <TableContainer
                style={{ width: "100%", backgroundColor: "#fff" }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" style={{ width: "100%" }}>
                      Quantity
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "100%" }}>
                      Unit
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell style={{ padding: 0 }} align="center">
                      <Typography align={"center"} variant="h6" align="center">
                        {props.subTaskData.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: 0 }} align="center">
                      <Typography align={"center"} variant="h6" align="center">
                        {props.subTaskData.unit}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </TableContainer>
            </Grid> */}
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 60, padding: 20, backgroundColor: "#345BAE" }}
          >
            <Grid container justify="center" alignItems="center">
              <Grid item className="m-4">
                <Typography variant="h6" style={{ color: "#fff" }}>
                  Update Details:
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify="center">
              {/* {console.log(props.activityUpdateDetails)} */}
              {props.activityUpdateDetails != undefined &&
                props.activityUpdateDetails.length > 0
                ? props.activityUpdateDetails.map((detail, detailIndex) => {
                  return (
                    <Card
                      variant="outlined"
                      style={{ padding: "5px", width: "100%" }}
                      elevation={3}
                    >
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography className={classes.getcol}>
                              <strong>Updated by: </strong>
                              {detail.user}
                            </Typography>
                            <Typography className={classes.getcol}>
                              <strong>Update Date : </strong>
                              {detail.date}
                            </Typography>
                            <Typography className={classes.getcol}>
                              <strong>Update Percentage: </strong>
                              {detail.updatedPercentage}%
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                if (detail.full_panaroma_url)
                                  handleShowDialog(detail.full_panaroma_url);
                                else handleShowDialog(detail.panaroma);
                              }
                              }
                            // startIcon={<Info />
                            >
                              View Panaroma
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  );
                })
                : "No Updates"}
              {/* <Grid item style={{ position: "relative" }}>
                  <IconButton
                    aria-label="settings"
                    // onClick={this.handleExpandClick}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      backgroundColor: "red",
                      color: "#fff",
                      padding: 4,
                    }}
                  >
                    <Delete />
                  </IconButton>
                  <img
                    className={classes.image}
                    src={`https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg`}
                    alt="ss"
                    loading="lazy"
                  />
                </Grid>
                <Grid item style={{ position: "relative" }}>
                  <IconButton
                    aria-label="settings"
                    // onClick={this.handleExpandClick}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      backgroundColor: "red",
                      color: "#fff",
                      padding: 4,
                    }}
                  >
                    <Delete />
                  </IconButton>
                  <img
                    className={classes.image}
                    src={`https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg`}
                    alt="ss"
                    loading="lazy"
                  />
                </Grid>
                <Grid item style={{ position: "relative" }}>
                  <IconButton
                    aria-label="settings"
                    // onClick={this.handleExpandClick}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      backgroundColor: "red",
                      color: "#fff",
                      padding: 4,
                    }}
                  >
                    <Delete />
                  </IconButton>
                  <img
                    className={classes.image}
                    src={`https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg`}
                    alt="ss"
                    loading="lazy"
                  />
                </Grid> */}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
