import React, { Component } from "react";
import {
  getMasterListContractorRA,
  updateContractorRAItem,
  deleteContractorRAItem,
} from "../../../Redux/storeRedux/storeAction";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Add as AddIcon,
  Edit,
  Delete,
} from "@material-ui/icons";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  useMediaQuery,
  DialogContentText,
  Button,
  DialogTitle,
  CardContent,
  Typography,
  Grid,
  Card,
} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import _ from "lodash";
import { Link } from "react-router-dom";
import Editlist from "../Editlist";
import Edititem from "./EditItem";
import Loading from "../../../Components/Loading";
import MUIDataTable from "mui-datatables";
import Swal from 'sweetalert2';
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
class DisplayContractorRA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: [],
      open: false,
      itemOpen: false,
      isAdditem: false,
      itemName: "",
      rate: 0,
      unit: "",
      item: "",
      editIndex: 0,
      editItemIndex: 0,
      nameError: false,
      expanded: "panel1",
      filterContractor:"",
      contractorArray:[]
    };
  }
  filter = createFilterOptions();
  handleChange = panel => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };
  handleContractorDropdownChange = async (e, newValue) => {
    console.log("new value is this ",newValue)
    let temp = newValue != null ? newValue.contractor : ''
 
     console.log("setting this up right now ",temp)
    //if (temp != '')
    await  this.setState({
        filterContractor: temp,
        //visibleArray:[]
      });
      console.log("filter contractor is set to go ",
      this.state.filterContractor)
  };
  handleFieldsChange = async (name, value) => {
    let found = false;
    if (name === "itemName") {
      this.props.contractorRAData.forEach((contractor, index) => {
        if (
          contractor.contractorName === value.trim() &&
          this.state.editIndex !== index
        ) {
          found = true;
          return;
        }
      });
      if (found) {
        this.setState({ nameError: true });
        //return;
      } else {
        this.setState({ nameError: false });
      }
    }
    if (this.state[name] !== value)
      await this.setState({
        [name]: value.trim(),
      });
  };
  handleItemChange = (name, value) => {
    this.setState({
      [name]: value.trim(),
    });
  };
  async componentDidUpdate() {
    let new_store = [];
    if (this.state.store.length !== this.props.contractorRAData.length) {
      this.props.contractorRAData.forEach((contractor, index) => {
        new_store.push({
          //"id":index+1,
          ...contractor,
          // labourName: labour.labourName,
          // labourUnit: labour.labourUnit,
          // labourRate: labour.labourRate,
          // "_id":labour._id,
          // "projectId":labour.projectId
        });
      });
      let currContractorArray=[]
      this.props.contractorRAData.forEach((contractor,index) =>{
            currContractorArray.push({
              id:index,
              contractor:contractor.contractorName
            })
      })
      this.setState({
        contractorArray:currContractorArray,
        store: new_store,
      });
    }
  }
  async componentDidMount() {
    await this.props.getMasterListContractorRA();
    if (!this.props.getStoreLoading) {
      console.log(this.props.contractorRAData);
      console.log("contractorRADATA", this.props.contractorRAData);

      
      let currContractorArray=[]
      this.props.contractorRAData.forEach((contractor,index) =>{
            currContractorArray.push({
              id:index,
              contractor:contractor.contractorName
            })
      })
      this.setState({
        contractorArray:currContractorArray,
        store: this.props.contractorRAData,
      });
    }
    console.log("labour data is here", this.props.contractorRAData);
  }
  deleteMaterial = i => {
    let id = this.state.store[i]._id;
    var arr = [...this.state.store];
    arr.splice(i, 1);
    this.props.deleteContractorRAItem(id);
    // this.setState({
    //   store: arr,
    // });
    // console.log("soter is ",this.state.store)
  };
  handleEdit = (itemName, pancard_no, gst_no, rowIndex) => {
    this.setState({
      itemName,
      pancard_no,
      gst_no,
      open: true,
      editIndex: rowIndex,
    });
  };
  handleItemEdit = (c_index, itemIndex) => {
    this.setState({
      itemOpen: true,
      editIndex: c_index,
      editItemIndex: itemIndex,
      item: this.state.store[c_index].items[itemIndex].item,
      unit: this.state.store[c_index].items[itemIndex].unit,
      rate: this.state.store[c_index].items[itemIndex].rate,
    });
  };
  handleModalOpen = () => {
    this.setState({
      open: false,
      itemName: "",
      rate: 0,
      unit: "",
      editIndex: 0,
      item: "",
      nameError: false,
    });
  };
  handleItemModalOpen = () => {
    this.setState({
      itemOpen: false,
      item: "",
      rate: 0,
      unit: "",
    });
  };
  handleDeleteItem = (c_index, itemIndex) => {
    let new_contractor = this.state.store;
    let new_items = new_contractor[c_index].items;
    new_items.splice(itemIndex, 1);
    new_contractor.items = new_items;
    let id = this.state.store[c_index]._id;
    this.props.updateContractorRAItem(id, new_contractor[c_index]);
    this.setState({
      store: new_contractor,
    });
  };
  handleAddItem = e => {
    e.preventDefault();
    let new_contractor = this.state.store;
    new_contractor[this.state.editIndex].items.push({
      item: this.state.item,
      rate: this.state.rate,
      unit: this.state.unit,
    });
    console.log("new items aer ", new_contractor[this.state.editIndex].items);
    let id = this.state.store[this.state.editIndex]._id;
    this.props.updateContractorRAItem(id, new_contractor[this.state.editIndex]);
    this.setState({
      store: new_contractor,
      itemOpen: false,
      isAdditem: false,
      item: "",
      rate: "",
      unit: "",
    });
  };
  AddItemModalOpen = c_index => {
    this.setState({
      itemOpen: true,
      isAdditem: true,
      item: "",
      rate: "",
      unit: "",
      editIndex: c_index,
    });
  };
  AddItemModalCancel = () => {
    this.setState({
      itemOpen: false,
      isAdditem: false,
      item: "",
      rate: "",
      unit: "",
    });
  };
  handlesubmit = e => {
    e.preventDefault();
    if (this.state.nameError) return;

    console.log("handlesubmit ", this.state.unit);
    let new_items = this.state.store;
    new_items[this.state.editIndex] = {
      ...new_items[this.state.editIndex],
      contractorName: this.state.itemName,
      contractorRate: this.state.rate,
      contractorUnit: this.state.unit,
      contractorItem: this.state.item,
      contractorPancardNo: this.state.pancard_no,
      contractorGSTNo: this.state.gst_no,
    };
    let id = this.state.store[this.state.editIndex]._id;
    console.log("unit rate is ", new_items[this.state.editIndex]);
    this.props.updateContractorRAItem(id, new_items[this.state.editIndex]);

    this.setState({
      open: false,
      store: new_items,
    });

    //  console.log("we have new sotre",this.state.store)
  };
  handleItemsubmit = e => {
    e.preventDefault();
    console.log("submiteeed");
    let new_contractor = this.state.store;
    new_contractor[this.state.editIndex].items[this.state.editItemIndex] = {
      item: this.state.item,
      rate: this.state.rate,
      unit: this.state.unit,
    };
    let id = this.state.store[this.state.editIndex]._id;
    console.log("id", id, new_contractor[this.state.editIndex]);
    this.props.updateContractorRAItem(id, new_contractor[this.state.editIndex]);
    this.setState({
      store: new_contractor,
      item: "",
      rate: "",
      unit: "",
      editIndex: 0,
      editItemIndex: 0,
      itemOpen: false,
    });
  };
  columns = [
    {
      name: "SN",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        }
      }
    },
    {
      name: "item",
      label: "Item",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unit",
      label: "unit",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "rate",
      label: "rate",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Edit",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <button
              onClick={() => {
              let c_index=parseInt(this.state.expanded.split("panel")[1]) -1
                this.handleItemEdit(c_index, tableMeta.rowIndex)
              }}
              style={{
                background:"#345BAE",
                color:'white',
                border:"1px solid transparent",
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Edit  style={{width: ".65em", height:".65em", marginRight:"2px"}} />
              Edit
            </button>
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <button onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  )
                  console.log("delte val",value,tableMeta.rowIndex)
                  let c_index=parseInt(this.state.expanded.split("panel")[1]) -1
                  console.log("ciindex",c_index)
                  this.handleDeleteItem(c_index, tableMeta.rowIndex)
                }
              })
             
            }}
            style={{
              background:"#F24236",
              color:'white',
              border:"1px solid transparent",
              borderRadius: "3px",
              display: "flex",
              alignItems: "center",
            }}>
              <Delete  style={{width: ".65em", height:".65em", marginRight:"2px"}} />
              Delete
            </button>
          );
        },
      },
    },
  ];

  render() {
    return (
      <>
        {this.props.getStoreLoading ? (
          <Loading loading={this.props.loading} />
        ) : (
          <div className="container mt-2" style={{ marginBottom: "1em" }}>
            <h3 className="mt-4 mb-3">Contractors Registered</h3>
            <Editlist
              itemName={this.state.itemName}
              open={this.state.open}
              handleModalOpen={this.handleModalOpen}
              handlesubmit={this.handlesubmit}
              handleFieldsChange={this.handleFieldsChange}
              isContractorRA={true}
              nameError={this.state.nameError}
              pancard_no={this.state.pancard_no}
              gst_no={this.state.gst_no}
            />
            {this.state.store[this.state.editIndex] && (
              <Edititem
                open={this.state.itemOpen}
                isAdditem={this.state.isAdditem}
                unit={this.state.unit}
                rate={this.state.rate}
                item={this.state.item}
                handleItemChange={this.handleItemChange}
                handleItemsubmit={this.handleItemsubmit}
                handleItemModalCancel={this.AddItemModalCancel}
                handleAddItem={this.handleAddItem}
                handleItemModalOpen={this.handleItemModalOpen}
              />
            )}
          </div>
        )}

        <Grid
        className="container mt-2"
         container 
         style={{
          width:"50%",
          display:"flex",
          justifyContent:"flex-start"
          }}>
             {/* for contractor */}
            <Autocomplete
            className="container mt-4 mb-3 my-3"
            style={{ width: 180 }}
            value={this.state.filterContractor}
            options={this.state.contractorArray}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            getOptionSelected={(option, value) => option.value === value.value}
            filterOptions={(options, params) => {
              const filtered = this.filter(options, params);
              if (params.inputValue !== "") {
                filtered.push({
                  value: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
            onChange={this.handleContractorDropdownChange}
            getOptionLabel={option => {
              if (typeof option === "string") {
                return option;
              }
              if (option.value) {
                return option.value;
              }

              return option.contractor;
            }}
            renderOption={option => option.contractor ? option.contractor : ""}
          //  className="my-3"
            freeSolo
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                name="searchContractor"
                label="Search contractor"
                variant="outlined"
                fullWidth
              />
            )}
            />
            </Grid>
        <div className="container" style={{ marginTop: "20px" }}>
          {this.state.store != undefined && this.state.store.length !== 0
            ? this.state.store.map((contractor, index) => {
              if(this.state.filterContractor ==="" || 
              this.state.filterContractor===contractor.contractorName)
                return (
                  <Accordion
                    expanded={this.state.expanded === `panel${index + 1}`}
                    onChange={this.handleChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            flexGrow: 1,
                          }}
                        >
                          <Typography variant="h6">
                            {contractor.contractorName}
                          </Typography>
                          {/* <Typography
                            style={{ marginRight: "20px" }}
                            color="textSecondary"
                            variant="h6"
                          >
                            percentage
                          </Typography> */}
                        </div>
                        {
                          <div>
                            <Grid item>
                              <IconButton
                                aria-label="settings"
                                onClick={() => {
                                  this.handleEdit(
                                    this.state.store[index].contractorName,
                                    this.state.store[index].contractorPancardNo,
                                    this.state.store[index].contractorGSTNo,
                                    index
                                  );
                                }}
                                style={{
                                  backgroundColor: "#345BAE",
                                  color: "#fff",
                                  padding: 4,
                                  marginRight: 4,
                                  borderRadius: 4,
                                  fontSize:"1rem",
                                }}
                              >
                                <Edit  style={{width: ".65em", height:".65em"}} />
                                Edit
                              </IconButton>
                              <IconButton
                                aria-label="settings"
                                onClick={() => {
                                  Swal.fire({
                                    title: 'Are you sure?',
                                    text: "You won't be able to revert this!",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes, delete it!'
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                      )
                                      this.deleteMaterial(index);
                                    }
                                  })
                                 
                                }}
                                style={{
                                  backgroundColor: "red",
                                  color: "#fff",
                                  padding: 4,
                                  borderRadius: 4,
                                  fontSize:"1rem"
                                }}
                              >
                                <Delete  style={{width: ".65em", height:".65em"}} />
                                Delete
                              </IconButton>
                            </Grid>
                          </div>
                        }
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "inline" }}>
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justify="center"
                        style={{
                          marginBottom: 20,
                          padding: 20,
                        }}
                      >
                        <Grid item lg={6}></Grid>
                      </Grid>
                      {
                        <Grid
                          container
                          direction="row"
                          style={{ marginTop: "2em" }}
                          justify="flex-end"
                        >
                          <Grid item>
                            <Button
                              className="m-2"
                              color="primary"
                              onClick={() => {
                                this.AddItemModalOpen(index);
                              }}
                              startIcon={<AddIcon />}
                            >
                              Add item
                            </Button>
                          </Grid>
                        </Grid>
                      }
                      <MUIDataTable
              title={`Items`}
              data={contractor.items}
              columns={this.columns}
              options={{
                selectableRows: "none",
                responsive: "verticle",
                pagination: false,
                download: false,
                viewColumns: false,
                filter: true,
                print: false,
              }}
            />
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : ""}
        </div>
      </>
    );
  }
}
//

const mapStateToProps = state => {
  return {
    //   check weather getStoreloading ??
    getStoreLoading: state.storeReducer.getStoreLoading,
    contractorRAData: state.storeReducer.contractorRAData,
  };
};

export default connect(mapStateToProps, {
  getMasterListContractorRA,
  updateContractorRAItem,
  deleteContractorRAItem,
})(DisplayContractorRA);