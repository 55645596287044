import {
  ADD_LOADING,
  TODO_SUCCESS,
  TODO_FAILURE,
  GET_TODO,GET_MONTH_TODO
} from "./todoActionTypes";
import axios from "axios";
import Notifications from "react-notification-system-redux";

const successDeleteOpts = {
  title: "Success",
  message: "Todo added successfully!",
  position: "tr",
  autoDismiss: 3
};
const successRemarkOpts = {
  title: "Success",
  message: "Todo Remark successfully Added!",
  position: "tr",
  autoDismiss: 3
};
const successUpdateTodoOpts = {
  title: "Success",
  message: "Edited task successfully added!",
  position: "tr",
  autoDismiss: 3
};
const successNotificationOpts = {
  title: "Success",
  message: "Todo added successfully!",
  position: "tr",
  autoDismiss: 3
};

const errorNotificationOpts = {
  title: "Error",
  message: "Something went wrong!!",
  position: "tr",
  autoDismiss: 3
};
const convert = (str)=> {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
export const getTodo = (date) => async dispatch => {
  dispatch({
    type: ADD_LOADING
  });
  const id = global.config.secureStorage.getItem("subprojectId");
  try {
    const response = await axios.get(`${global.config.backendURL}/todo/getTodo/${id}`, {
      params: {
        date: date
      }
    });
  
    var data = response.data;
    const role = global.config.secureStorage.getItem("role");


    const userId = global.config.secureStorage.getItem("user_id")


    const roles = [
      "super_admin"
    ];


    let newData = []
    if (!roles.find(item => item == role)) {
      data = data.filter(item => item.viewUsersIds.includes(userId) || item.assignUsersIds.includes(userId)
      ||item.todoAdminId ==userId);

      data.filter(function (item) {
        if (item.assignUsersIds.includes(userId) ||item.todoAdminId ==userId) {
          item['isEditable'] = true
          newData.push(item)
        } else if (item.viewUsersIds.includes(userId)){
          item['isEditable'] = false
          newData.push(item)
        }
      });

    } else {
      data.filter(function (item) {

        item['isEditable'] = true
      });

      newData = data
    }

    dispatch({
      type: GET_TODO,
      payload: newData
    });
    dispatch({
      type: TODO_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: TODO_FAILURE,
      payload: error.message
    });
    dispatch(Notifications.error(errorNotificationOpts));
  }
};
export const getTodoMonthWise = (startdate,enddate) => async dispatch => {

  const id = global.config.secureStorage.getItem("subprojectId");
  try {
    const response = await axios.get(`${global.config.backendURL}/todo/getMonthTodo/${id}/${startdate}/${enddate}`);
    console.log(response)

    dispatch({
      type: GET_MONTH_TODO,
      payload: response
    });
    dispatch({
      type: TODO_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: TODO_FAILURE,
      payload: error.message
    });
    dispatch(Notifications.error(errorNotificationOpts));
  }
};
export const getSingleTodo = (todoId) => async dispatch => {
  dispatch({
    type: ADD_LOADING
  });

  const id = global.config.secureStorage.getItem("subprojectId");
  try {
    const response = await axios.get(`${global.config.backendURL}/todo/getSingleTodo/${id}`, {
      params: {
        todoId: todoId
      }
    });
    var data = response.data;

    const userId = global.config.secureStorage.getItem("user_id")

    const role = global.config.secureStorage.getItem("role");


    const roles = [
      "super_admin"
    ];


    let newData = []
    if (!roles.find(item => item == role)) {

      if (data.assignUsersIds.includes(userId)) {
        data['isEditable'] = true
      } else {
        data['isEditable'] = false
      }
      newData = data
    } else {

      data['isEditable'] = true


      newData = data
    }

    dispatch({
      type: GET_TODO,
      payload: newData
    });
    dispatch({
      type: TODO_SUCCESS
    });

  } catch (error) {
    dispatch({
      type: TODO_FAILURE,
      payload: error.message
    });
    dispatch(Notifications.error(errorNotificationOpts));
  }
};

export const addTodo = todo => async dispatch => {
  dispatch({
    type: ADD_LOADING
  });
  todo["subprojectId"] = global.config.secureStorage.getItem("subprojectId");
  todo["todoAdminId"] = global.config.secureStorage.getItem("user_id");
  try {
    await axios.post(`${global.config.backendURL}/todo/addTodo`, todo);
    dispatch({
      type: TODO_SUCCESS
    });
    const TodayDate =
    new Date().getFullYear()+
    "-" +(parseInt(new Date().getMonth()) + 1).toString().padStart(2, "0") +
    "-" + new Date().getDate().toString().padStart(2, "0");
    dispatch(getTodo(TodayDate));

    var firstDay = convert(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    var lastDay = convert(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    dispatch(getTodoMonthWise(firstDay,lastDay));
    dispatch(Notifications.success(successNotificationOpts));
  } catch (error) {
    dispatch({
      type: TODO_FAILURE,
      payload: error.message
    });
    dispatch(Notifications.error(errorNotificationOpts));
  }
};

export const deleteTodo = id => async dispatch => {
  dispatch({
    type: ADD_LOADING
  });
  try {

    const response = await axios.delete(
      `${global.config.backendURL}/todo/deleteTodo/${id}`
    );
    dispatch({
      type: TODO_SUCCESS,
      payload: response.data
    });
    dispatch(getTodo());
    dispatch(Notifications.success(successDeleteOpts));
  } catch (error) {
    dispatch({
      type: TODO_FAILURE,
      payload: error.message
    });
    dispatch(Notifications.error(errorNotificationOpts));
  }
};
export const addRemark = (id, todo) => async dispatch => {
  todo["username"] = global.config.secureStorage.getItem("user_fullname");
  todo["user_id"] = global.config.secureStorage.getItem("user_id");
  dispatch({
    type: ADD_LOADING
  });


  try {

    const config = {
      onUploadProgress: progressEvent => console.log(progressEvent.loaded)
    }
    let todoId = todo.todoId;

    delete todo.todoId;

    await axios.put(`${global.config.backendURL}/todo/addRemark/${id}`, todo, config)
    dispatch(getSingleTodo(todoId));
    dispatch(Notifications.success(successRemarkOpts));
  } catch (error) {
    dispatch({
      type: TODO_FAILURE,
      payload: error.message
    });
    dispatch(Notifications.error(errorNotificationOpts));
  }
};
export const updateTodo = (id, todo) => async dispatch => {
  dispatch({
    type: ADD_LOADING
  });
  try {

    let todoId = todo.todoId;

    delete todo.todoId;

    await axios.put(`${global.config.backendURL}/todo/updateTodo/${id}`, todo)
    dispatch(getSingleTodo(todoId));
    dispatch(Notifications.success(successUpdateTodoOpts));
  } catch (error) {
    dispatch({
      type: TODO_FAILURE,
      payload: error.message
    });
    dispatch(Notifications.error(errorNotificationOpts));
  }
};
