import axios from 'axios';

export function ApiPostMethod(url, data, method = 'POST'){
    return fetch(url, {
      method: method,
      header: {'content-type' : 'multipart/form-data'},
      body: data
    })
    .then((resp) => resp.json())
    .then(function(result) {
        return result;
    });
}

export function ApiGetMethod(url, data, method = "GET"){
  return fetch(url, {
    method:method,
    header: {'content-type' : 'application/json, text/plain, */*  '}
  })
  .then((resp) => resp.json())
  .then(function(result) {
    return result;
  });
}
// export function ApiGetMethod(url, data, method = 'GET') {
//   return axios({
//     method: method,
//     url: url,
//     headers: { 'Content-Type': 'multipart/form-data' },
//     data: data
//   })
//     .then((response) => response.data)
//     .catch((error) => {
//       throw new Error(error);
//     });
// }
