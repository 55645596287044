import React, { useState, useEffect } from "react";
import { InputLabel, TextField, Grid, Button,IconButton } from "@material-ui/core";
import { Save, Cancel, Add as AddIcon,AddCircleOutline, Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Style from "./style.module.scss";
import { isInteger } from "lodash";
import _ from "lodash";
import Swal from 'sweetalert2';

const AddMaterial = props => {
  const [open, setOpen] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [materialsUnit, setMaterialsUnit] = useState([]);
  const [toggleText, setToggleText] = useState(false);
  const loading = open && materials.length === 0;
  const [numberOfMaterial, setNumberOfMaterial] = useState(1);

  const [materialReq, setmaterialReq] = useState([]);

  const handleMaterialAddCount = () => {
    setNumberOfMaterial(numberOfMaterial + 1);
  };

  const handleMaterialDeleteCount = index => {
    if (numberOfMaterial === 1) return;
    let temp = materialReq;
    temp.splice(index, 1);
    setmaterialReq(temp);
    setNumberOfMaterial(numberOfMaterial - 1);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    var tempMaterials = new Set();
    (() => {
      props.storeData.map(ele => {
        return tempMaterials.add(ele.materialName);
      });
      setMaterials(Array.from(tempMaterials));
    })();
  }, [loading]);
  
  

  useEffect(() => {
    if (!open) {
      setMaterials([]);
    }
  }, [open]);

  // console.log("expected date....", props.expectedDate);

    return (

    <div>
      <section className={Style.create__material}>
        <form onSubmit={(event)=>{event.preventDefault();props.saveMaterial(materialReq)}}>
          <h1 className={Style.add__material__header}>Create New Material Requisition</h1>
              <TextField
                  name="Title"
                  id="materialTitle"
                  variant="outlined"
                  onChange={props.handleChangeName}
                  label="Title"
                  value={props.materialReqName}
                  type="text"
                  required
                  fullWidth
                />  
           <br />
           <br />
          <InputLabel>Expected Delivery By</InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              disableToolbar
              variant="dialog"
              format="dd/MM/yyyy"
              minDate={new Date()}
              margin="normal"
              value={props.expectedDate === "" ? new Date() : props.expectedDate}
              onChange={(date)=>props.handleExpectedDate(date)}
              name="expectedDate"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <br />
         
          {_.times(numberOfMaterial, index => {
            let indexValue = index + 1;
            return (
              <>
                <Grid container style={{marginTop:'20px'}}>
                  <Grid item xs={10}>
                    <h6>Material {indexValue}</h6>
                    <Autocomplete
                      // fullWidth
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      id="autoComplete"
                      name="materialName"
                      onChange={(event, newValue) => {
                        let RequisitionArr = [...materialReq]
                        const matchedMaterial = props.storeData.find(
                          (item) => item.materialName === newValue
                        );
                        if (matchedMaterial) {
                          RequisitionArr[index] = {
                            materialName: newValue,
                            materialUnit: matchedMaterial.materialUnit,
                          };
                        } else {
                          RequisitionArr[index] = {
                            materialName: newValue,
                            materialUnit: "",
                          };
                        }
                        setmaterialReq(RequisitionArr)
                        console.log("newValue", materials)
                      }}
                      value={
                        typeof materialReq[index] === "object" &&
                        materialReq[index] !== null
                          ? materialReq[index].materialName
                          : ""
                      }
                      options={materials}
                      getOptionLabel={ele => ele}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          label={"Select Material"}
                          variant="outlined"
                          onClick={() => {
                          }}
                        />
                      )}
                    />
                
                <br />
                <TextField
                  name="materialQuantity"
                  id="materialQuantity"
                  margin="dense"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}  

                  onChange={(event) => {
                        console.log(materialReq)
                    let RequisitionArr = [...materialReq]
                      RequisitionArr[index] = {
                        materialName: RequisitionArr[index].materialName,
                        materialQuantity: event.target.value,
                        materialUnit: RequisitionArr[index].materialUnit,
                      };
                    setmaterialReq(RequisitionArr)
                  }}
                  label="Quantity"
                  value={
                    typeof materialReq[index] === "object" &&
                    materialReq[index] !== null
                      ? materialReq[index].materialQuantity
                      : ""
                  }
                  type="number"
                  required
                  fullWidth
                />  
                <TextField
                  style={{marginTop:'20px'}}
                  name="materialUnit"
                  id="materialUnit"
                  margin="dense"
                  variant="outlined"
                  label="Unit"
                  InputLabelProps={{ shrink: true }}  
                  onChange={(event) => {
                        
                    let RequisitionArr = [...materialReq]
                    RequisitionArr[index] = {
                      materialName: RequisitionArr[index].materialName,
                      materialQuantity: RequisitionArr[index].materialQuantity,
                      materialUnit: event.target.value,
                    };
                    setmaterialReq(RequisitionArr)
                  }}
                  value={
                    typeof materialReq[index] === "object" &&
                    materialReq[index] !== null
                      ? materialReq[index].materialUnit
                      : ""
                  }
                  type="text"
                  required
                  fullWidth
                />  
                  </Grid>
                  <Grid item xs={2} style={{textAlign:'center'}}>
                    <IconButton
                      color="secondary"
                      className="mt-4"
                      onClick={() => {
                        if (numberOfMaterial !== 1){
                          Swal.fire({
                            title: 'Are you sure?',
                            text: "You won't be able to revert this!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, delete it!'
                          }).then((result) => {
                            if (result.isConfirmed) {
                              Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                              )
                              handleMaterialDeleteCount(index)
                            }
                          })
                        }else{
                          Swal.fire({
                            icon: "info",
                            title: "info..",
                            text: "Add atlest one material!"
                          })
                        }
                      }
                        
                      }>
                        <Delete >
                      </Delete>
                    </IconButton>
                  </Grid>
                  
                </Grid>
                  <hr/>
                {numberOfMaterial - 1 === index ? (
                    <Grid item xs={12} style={{textAlign:'center'}}>
                      <IconButton
                        color="primary"
                        className="mt-4"
                        onClick={() => handleMaterialAddCount(index)}
                      >
                        <AddCircleOutline />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item xs={6}></Grid>
                  )}
                </>
            );
          })}
         
          <br />
          
          <Grid justify="center" container>
            <Grid className="mr-4" item>
              <Button
                variant="contained"
                size="large"
                type="submit"
                startIcon={<Save />}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                size="large"
                onClick={props.toggleCreateNewMaterial}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </section>
    </div>
  )
};
export default AddMaterial;