import React, { useState, useEffect } from "react";
import useStyles from "./DrawerStyle";
import {
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  SwipeableDrawer,
  Typography,
  FormControl,
  Select,
  InputLabel,
  Menu,
  MenuItem,
  Button,
  ListItemText,
} from "@material-ui/core";
import { Link as ScrollLink } from "react-scroll";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { drawerDataUtil } from "./DrawerData";
import DrawerItemWithContent from "./DrawerItemWithContent";
import { getUserProjects } from "../../Redux/projectRedux/projectAction";
import { setupNavTitle } from "./utils";
import { logout } from "../../Redux/loginRedux/loginAction";
import "./style.css"
import { trusted } from "mongoose";
import { TramRounded } from "@material-ui/icons";
import Project from "../../Pages/ProjectList/Project";
import { secureStorage } from '../../../src/config';
import QuickList from "../../Pages/ProjectList/QuickList";
// import LogoutIcon from '@mui/icons-material/Logout';


function ClippedDrawer(props) {

  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(secureStorage.getItem('is_admin'));
  const [superProjects, setSuperProjects] = useState([]);
  const [selectedSuperProject, setSelectedSuperProject] = useState('');
  const [title, setTitle] = useState("Enbuild");
  const [drawer, setDrawer] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [subprojects, setSubprojects] = useState([]);
  const [subprojectName, setSubprojectName] = useState("");
  const [isLogout, setLogout] = useState(false);
  const [isBack, setIsBack] = useState(false);
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    if (
      props.userProjects.length > 0 &&
      props.userProjectSuccess &&
      superProjects.length === 0
    ) {
      const projects = [...props.userProjects];
      const tempSProjects = [];
      projects.forEach((singleProject) => {
        if (singleProject.superProject)
          tempSProjects.push(singleProject.superProject);
      });

      const uniqueArray = tempSProjects.filter((item, pos) => {
        return tempSProjects.indexOf(item) == pos;
      });
      setSuperProjects(uniqueArray);
      setSelectedSuperProject(uniqueArray[0]);
      secureStorage.setItem('superProject', uniqueArray[0]);
    }
  }, [props.userProjects, props.userProjectSuccess, superProjects]);

  const handleSuperProjectChange = (e) => {
    setSelectedSuperProject(e.target.value);
    secureStorage.setItem('superProject', e.target.value);
  };

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [drawerItems, setDrawerItems] = useState(() => {
    const role = global.config.secureStorage.getItem("role");
    return drawerDataUtil(role);
  });
  const his = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [switchIcon, setSwitchIcon] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState((localStorage.hasOwnProperty('projectIndex')) ? JSON.parse(localStorage.getItem('projectIndex')) : 0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSwitchIcon(true)
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    localStorage.setItem("projectIndex", index)
    setAnchorEl(null);
    setSwitchIcon(false)
    const selectedProject = props.userProjects[index];

    // Store the _id and projectName in global.config.secureStorage
    global.config.secureStorage.setItem("projectId", selectedProject._id);
    global.config.secureStorage.setItem("projectName", selectedProject.projectName);

  };

  const handleClose = () => {
    setAnchorEl(null);
    setSwitchIcon(false)
  };
  const ModalDrawerClose = () => {
    handleClose()
    props.toggleDrawer()
    
  }

  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
const handleSetActiveRoute = (route) => {
  setActiveRoute(route);
};
const [drawerInvisibleRoutes] = useState([
  "/projectList",
  "/subprojectList",
  "/login",
  "/register",
  "/registerAdmin",
  "/addProject",
  "/dashboard",
]);
const [logoutInVisibleRoutes] = useState([
  "/login",
  "/register",
  "/registerAdmin",
]);

const logout = () => {
  // global.config.secureStorage.clear();
  props.logout();
  his.push("/login");
  // setLogoutState(true);
};

his.listen((location, action) => {
  //
  if (drawerInvisibleRoutes.find(item => item === location.pathname)) {
    setDrawerVisible(false);
  } else {
    setDrawerVisible(true);
  }
  if (logoutInVisibleRoutes.find(item => item === location.pathname)) {
    setLogout(false);
  } else {
    setLogout(true);
  }
  setupNavTitle(his.location.pathname, setTitle, setIsBack);
});

useEffect(() => {
  if (drawerInvisibleRoutes.find(item => item === his.location.pathname)) {
    setDrawerVisible(false);
  } else {
    setDrawerVisible(true);
  }
  if (logoutInVisibleRoutes.find(item => item === his.location.pathname)) {
    setLogout(false);
  } else {
    setLogout(true);
  }
  setupNavTitle(his.location.pathname, setTitle, setIsBack);
}, [his.location.pathname, logoutInVisibleRoutes, drawerInvisibleRoutes]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SwipeableDrawer
        anchor="left"
        className={classes.drawer}
        variant="temporary"
        open={props.isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onOpen={() => {
          props.toggleDrawer();
        }}
        onClose={() => {
          props.toggleDrawer();
        }}
      >
        <Toolbar />
        <List className={classes.drawerContainer}>
            <div>
              <List
                component="nav"
                aria-label="Device settings"
                sx={{ bgcolor: 'background.paper' }}
                className="d-flex"
              >
                <ListItem>
                  <div className="first-letter">
                    {(global.config.secureStorage.getItem("projectName"))? global.config.secureStorage.getItem("projectName").split(" ").map(word => word.charAt(0).toUpperCase()) : ''}
                  </div>
                  <ListItemText
                    primary={global.config.secureStorage.getItem("projectName")}
                    secondary={global.config.secureStorage.getItem("projectDescription")}
                  />
                </ListItem>
                <ListItem
                  button
                  id="lock-button"
                  aria-haspopup="listbox"
                  aria-controls="lock-menu"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickListItem}
                  style={{width : 'fit-content', padding: '0px'}}
                >
                  {(switchIcon === true) ? 
                    <svg class="feather feather-chevron-up" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline points="18 15 12 9 6 15"/></svg>
                  :
                    <svg class="feather feather-chevron-down" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"/></svg>
                  }
                </ListItem>

              </List>

              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={ModalDrawerClose}
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox',
                }}
              >
                {props.userProjects.map((item, index) => (
                  item.superProject === global.config.secureStorage.getItem("superProject") && (
                    <QuickList
                      project={item}
                      key={index}
                      history={props.history}
                      setLoading={toggleLoading}
                      admin={isAdmin}
                    />
                  )
                ))}
              </Menu>
            </div>
          {drawerItems.map((item, index) => {
            
            return (
              <>
                {his.location.pathname !== "/" ? (
                  <>
                    {item.content ? (
                      <DrawerItemWithContent
                        item={item}
                        toggleDrawer={props.toggleDrawer}
                        handleroute={handleSetActiveRoute}
                        activeRoute={activeRoute}
                      />
                    ) : (
                      <Link
                        to={item.route}
                        style={{ textDecoration: "none" }}
                        key={item.name}
                        onClick={() => handleSetActiveRoute(item.route)} className={activeRoute === item.route ? "activeitem" : ""}
                      >
                        <ListItem button onClick={props.toggleDrawer} >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <span className={classes.linkDrawer}>
                            {item.name}
                          </span>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    {item.content ? (
                      <DrawerItemWithContent
                        item={item}
                        toggleDrawer={props.toggleDrawer}
                      />
                    ) : (
                      <ScrollLink
                        spy={true}
                        smooth={true}
                        to={item.route}
                        style={{ textDecoration: "none" }}
                        key={item.name}
                        onClick={() => handleSetActiveRoute(item.route)} className={activeRoute === item.route ? classes.activeDrawerItem : ""}
                      >
                        <ListItem button onClick={props.toggleDrawer}>
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <span className={classes.linkDrawer}>
                            {item.name}
                          </span>
                        </ListItem>
                      </ScrollLink>
                    )}
                  </>
                )}
              
              </>
            );
          })}
          {isLogout &&
            (his.location.pathname === "/" ? props.loggedIn : true) && ( // Hide logout button only when on homepage
              <Button
                variant="outlined"
                style={{ color: "#000", border: "none"}}
                onClick={logout}
              >
                Logout
              </Button>
            )
          }
        </List>
        <Divider />
      </SwipeableDrawer>
      {his.location.pathname !== "/" ? (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Typography className={classes.projectName} variant="h6" >
            <strong>Project Name: {global.config.secureStorage.getItem("projectName")}</strong>
          </Typography>
        </div>) : ''}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    loggedIn: state.loginReducer.loggedIn,
  };
};

export default connect(mapStateToProps, {  
  logout,
})(ClippedDrawer);