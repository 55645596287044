import React, { Component } from "react";
import { Button, Grid } from "@material-ui/core";
import EditEquipment from "./EditEquipment";
import AddEquipment from "./AddEquipment";
// import { blue } from "@material-ui/core/colors";
import { Add } from "@material-ui/icons";
import Style from "./style.module.scss";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import Notifications from "react-notification-system-redux";
import axios from "axios";
import Swal from 'sweetalert2';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Add as AddIcon,
  Edit,
  Delete,
} from "@material-ui/icons";
// import Typography from "material-ui/styles/typography";
// import { each } from "lodash";

class Display extends Component {
  constructor(props) {
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const editRoles = ["purchase_manager", "project_manager", "super_admin"];
    const isEditable = editRoles.find(item => item == role) ? true : false;
    this.state = {
      equipmentName: "",
      equipmentQuantity: "",
      equipmentRate: "",
      equipmentAmount: "",
      activity: "",
      unit: "",
      // mainActivity: "",
      isCreateNewEquipment: false,
      isEditEquipment: false,
      editId: "",
      isEditable: isEditable,
      equipmentData: [],
      activities: [],
      subAccName: "",
      taskList: [],
      taskName: "",
      activityIndex: -1,
      subAccIndex: -1,
    };
  }
  async getEquipmentData() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    try {
      const res = await axios.post(
        `${global.config.backendURL}/equipment/getEquipment`,
        {
          projectId: projectId,
        }
      );
      this.setState({ equipmentData: res.data });
    } catch (err) {
      console.log(err);
    }
  }
  handleEquipmentChange = event => {
    this.setState({ equipmentName: event.target.value });
  };
  async componentDidMount() {
    await this.getEquipmentData();
    this.getActivities()
    console.log("equipment data");
    console.log(this.state.equipmentData);
  }

  handleChange = (event, value = null) => {
    if (value === null) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    } else {
      this.setState({
        equipmentName: value,
      });
    }
  };
  handleTabChange= () =>{
    this.setState({
         taskName:"",
         taskList:"",
         subAccName:"",
         subAccIndex:""
    })
  }
  handleActivity = event => {
    this.setState({
      activity: event.target.value,
      activityIndex: event.target.value.split(";")[1],
      taskCost:"",
      taskName:"",
      taskList:[],
      subAccName:"",
      subAccIndex:""
    });
  };
  handleSubActivity = async event => {
    let new_task;

    try {
      let index = event.target.value.split(";")[1];
      let subAccLink =
        this.state.activities[this.state.activityIndex].content[index].link;
      const subprojectId = global.config.secureStorage.getItem("subprojectId");
      const res = await axios.get(
        `${global.config.backendURL}/activities/getActivity?activity=${subAccLink}&subprojectId=${subprojectId}`
      );
      new_task = res.data;
    } catch (err) {
      console.log("err is ", err);
    }
    this.setState({
      subAccName: event.target.value,
      subAccIndex: event.target.value.split(";")[1],
      taskList: new_task,
      taskName:"",
      taskCost:""
    });
  };
  handleTaskChange = event => {
    let index = event.target.value.split(";")[1];
    this.setState({
      taskName: event.target.value,
   //   taskCost: this.state.taskList[index].taskCost,
    });
  };
  getActivities = async () => {
    let projectId = await global.config.secureStorage.getItem("projectId");
    const response = await axios.get(
      `${global.config.backendURL}/activities/getDefaultActivities/${projectId}`
    );
    // if(projectType === "Infrastructure"){
    //   const mainActivities = response.data.data.map((mainAct,index)=>{
    //     console.log(mainAct)
    //     return {
    //       id: index,
    //       title : maisnAct.name,
    //       activities : mainAct.data
    //     };
    //   });
    //   setMainActivities(mainActivities);
    // }
    // else{
    //   setActivities(response.data.data[0].data);
    // }
    let final = [];
    response.data.data.forEach(element => {
      if (element.visibility) final.push(element);
    });
    this.setState({
      activities: final,
    });
  };
  handleMainActivity = event => {
    console.log(event);
    this.setState({ mainActivity: event.target.value });
  };

  toggleEditEquipment = tableMeta => {
    let singleEquipment = [...this.state.equipmentData];
    singleEquipment = singleEquipment[tableMeta.rowIndex];
    console.log("rowIndex" + tableMeta.rowIndex);
    console.log(singleEquipment);
    let new_task=[];
    let acc_index;
    let task_index;
    let subacc_index;
    console.log("this state activites is ",this.state.activities,singleEquipment)
    this.state.activities.forEach( async (acc,accIndex) =>{
      console.log("equaliazing them ",acc.title,singleEquipment.activity)
            if(acc.title === singleEquipment.activity)
            {
              console.log("acc ndiex is ",accIndex)
              acc_index=accIndex
              const subprojectId = global.config.secureStorage.getItem("subprojectId");
                 acc.content.forEach(async  (subacc,subIndex)=>{
                  if(subacc.subTitle=== singleEquipment.subactivity)
                  {
                    console.log("indexes are",subIndex)
                    subacc_index=subIndex
                    const res = await axios.get(
                      `${global.config.backendURL}/activities/getActivity?activity=${subacc.link}&subprojectId=${subprojectId}`
                    );
                    let new_taskCost;
                    res.data.forEach((task,taskindex)=>{
                      if(singleEquipment.taskName===task.name)
                      {
                        new_taskCost=task.taskCost
                        task_index=taskindex
                        return
                      }
                    })
                    this.setState({
                      taskList:res.data,
                      taskName:  singleEquipment.taskName + ";" + task_index,
                      taskCost:new_taskCost,
                      activityIndex:acc_index,
                      subAccIndex:subacc_index,
                    })
                  }
                 })
            }
    })
    if (!this.state.isEditEquipment) {
      this.setState({
        editId: singleEquipment._id,
        equipmentName: singleEquipment.equipmentName,
        activity: singleEquipment.activity + ";" + acc_index,
        unit: singleEquipment.unit,
        equipmentRate: singleEquipment.equipmentRate,
        equipmentAmount: singleEquipment.equipmentAmount,
        equipmentQuantity: singleEquipment.equipmentQuantity,
        isEditEquipment: !this.state.isEditEquipment,
        subAccName: singleEquipment.subactivity + ";" + subacc_index,
        activityIndex:acc_index,
        subAccIndex:subacc_index,
      });
    }
  };

  toggleCreateNewEquipment = () => {
    this.setState({
      subAccName: "",
      taskList: [],
      taskName: "",
      taskCost: "",
      activityIndex: -1,
      subAccIndex: -1,
      equipmentName: "",
      activity: "",
      unit: "",
      equipmentRate: "",
      equipmentAmount: "",
      equipmentQuantity: "",
      isCreateNewEquipment: !this.state.isCreateNewEquipment,
    });
  };

  deleteEquipment = async id => {
    let singleEquipment = [...this.state.equipmentData];
    singleEquipment = singleEquipment[id];
    try {
      const res = await axios.post(
        `${global.config.backendURL}/equipment/deleteEquipment/${singleEquipment._id}`
      );
      let tempData = this.state.equipmentData;
      tempData.splice(id, 1);
      console.log(tempData);
      this.setState({
        equipementName: "",
        equipmentQuantity: "",
        equipmentRate: "",
        equipmentAmount: "",
        activity: "",
        unit: "",
        equipmentData: tempData,
      });
    } catch (err) {
      console.log(err);
    }
  };

  updateEquipment = async event => {
    event.preventDefault();

    let projectId = await global.config.secureStorage.getItem("projectId");

    const finalEquipment = {
      projectId,
      equipmentName: this.state.equipmentName,
      equipmentQuantity: this.state.equipmentQuantity,
      equipmentRate: this.state.equipmentRate,
      equipmentAmount: this.state.equipmentRate * this.state.equipmentQuantity,
      unit: this.state.unit,
      activity: this.state.activity.split(";")[0],
      _id: this.state.editId,
      subactivity: this.state.subAccName.split(";")[0],
      taskName: this.state.taskName.split(";")[0]
    };

    const num = Number(this.state.equipmentName);
    if (Number.isInteger(num)) {
      alert("Please enter valid equipment");
      this.setState({ equipmentName: "" });
    } else {
      let tempData = [...this.state.equipmentData];
      console.log(this.state.editId);
      if (this.state.editId != undefined) {
        try {
          const res = await axios.post(
            `${global.config.backendURL}/equipment/editEquipment/${this.state.editId}`,
            {
              ...finalEquipment,
            }
          );
        } catch (err) {
          console.log(err);
        }
        let objIndex = tempData.findIndex(obj => obj._id == this.state.editId);
        tempData[objIndex] = finalEquipment;
      }
      this.setState({
        editId: "",
        isEditEquipment: !this.state.isEditEquipment,
        equipementName: "",
        equipmentQuantity: "",
        equipmentRate: "",
        equipmentAmount: "",
        activity: "",
        unit: "",
        equipmentData: tempData,
        subactivity: "",
        taskList:"",
      });
    }
  };

  saveEquipment = async event => {
    event.preventDefault();
    let projectId = await global.config.secureStorage.getItem("projectId");

    const finalEquipment = {
      projectId,
      equipmentName: this.state.equipmentName,
      equipmentQuantity: this.state.equipmentQuantity,
      equipmentRate: this.state.equipmentRate,
      equipmentAmount: this.state.equipmentRate * this.state.equipmentQuantity,
      unit: this.state.unit,
      activity: this.state.activity.split(";")[0],
      subactivity: this.state.subAccName.split(";")[0],
      taskName: this.state.taskName.split(";")[0]
    };
    const num = Number(this.state.equipmentName);
    console.log(Number.isInteger(num));
    if (Number.isInteger(num)) {
      alert("Please enter valid equipment");
      this.setState({ equipmentName: "" });
    } else {
      try {
        const res = await axios.post(
          `${global.config.backendURL}/equipment/addToEquipment`,
          {
            ...finalEquipment,
          }
        );
      } catch (err) {
        console.log(err);
      }
      let tempData = [...this.state.equipmentData, finalEquipment];
      console.log(tempData);

      this.setState({
        equipementName: "",
        equipmentQuantity: "",
        equipmentRate: "",
        equipmentAmount: "",
        activity: "",
        unit: "",
        isEditEquipment: false,
        isCreateNewEquipment: !this.state.isCreateNewEquipment,
        equipmentData: tempData,
        subactivity: "",
        taskList:"",
        taskName: ""
      });
    }

    window.location.reload();
  };
  toggleeditmodal=()=>{
    this.setState({
      isEditEquipment: !this.state.isEditEquipment
    })
  }

  render() {

   const columns = [
      {
        name: "Serial Number",
        label: "SN",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return tableMeta.rowIndex + 1;
          }
        },
      },
      {
        name: "equipmentName",
        label: "Equipment",
        options: {
          filter: true,
          sort: true,
        },
      },
      // {
      //   name: "mainActivity",
      //   label: "Main Activity",
      //   options: {
      //     filter: true,
      //     sort: true,
      //   },
      // },
      {
        name: "activity",
        label: "Activity",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "subactivity",
        label: "Sub activity",
        options: {
          filter: true,
          sort: true,
          display: this.state.equipmentData.some(item => item.subactivity) ? "true" : "false",
        },
      },
      {
        name: "taskName",
        label: "Task name",
        options: {
          filter: true,
          sort: true,
          display: this.state.equipmentData.some(item => item.taskName) ? "true" : "false",
        },
      },
      {
        name: "unit",
        label: "Unit",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipmentQuantity",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipmentRate",
        label: "Rate",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipmentAmount",
        label: "Amount",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "Edit",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                onClick={() => {
                  
                  this.toggleEditEquipment(tableMeta);
                }}
                style={{
                  background:"#345BAE",
                  color:'white',
                  border:"1px solid transparent",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Edit  style={{width: ".65em", height:".65em", marginRight:"2px"}} />
                Edit
              </button>
            );
          },
        },
      },
      {
        name: "Delete",
        options: {
          customBodyRender: (value, tableMeta) => {
            return (
              <button onClick={() =>
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                    )
                    this.deleteEquipment(tableMeta.rowIndex)
                  }
                })
              }
              style={{
                background:"#F24236",
                color:'white',
                border:"1px solid transparent",
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
              }}
              >
                <Delete  style={{width: ".65em", height:".65em", marginRight:"2px"}} />
                Delete
              </button>
            );
          },
        },
      },
    ];

    const { isCreateNewEquipment, isEditEquipment } = this.state;
    if (isCreateNewEquipment) {
      return (
        <AddEquipment
          storeData={this.props.storeData}
          handleChange={this.handleChange}
          equipmentName={this.state.equipmentName}
          equipmentQuantity={this.state.equipmentQuantity}
          equipmentRate={this.state.equipmentRate}
          equipmentAmount={this.state.equipmentAmount}
          unit={this.state.unit}
          activity={this.state.activity}
          saveEquipment={this.saveEquipment}
          equipmentData={this.equipmentData}
          toggleCreateNewEquipment={this.toggleCreateNewEquipment}
          handleActivity={this.handleActivity}
          handleEquipmentChange={this.handleEquipmentChange}
          activityIndex={this.state.activityIndex}
          handleSubActivity={this.handleSubActivity}
          activities={this.state.activities}
          taskList={this.state.taskList}
          taskName={this.state.taskName}
          handleTaskChange={this.handleTaskChange}
          subAccName={this.state.subAccName}
          taskCost={this.state.taskCost}
          handleTabChange={this.handleTabChange}
        />
      );
    }
    if (isEditEquipment) {
      return (
        <EditEquipment
          storeData={this.props.storeData}
          handleChange={this.handleChange}
          equipmentName={this.state.equipmentName}
          equipmentQuantity={this.state.equipmentQuantity}
          equipmentRate={this.state.equipmentRate}
          equipmentAmount={this.state.equipmentAmount}
          unit={this.state.unit}
          activity={this.state.activity}
          updateEquipment={this.updateEquipment}
          equipmentData={this.equipmentData}
          toggleEditEquipment={this.toggleEditEquipment}
          handleActivity={this.handleActivity}
          handleEquipmentChange={this.handleEquipmentChange}
          activityIndex={this.state.activityIndex}
          handleSubActivity={this.handleSubActivity}
          activities={this.state.activities}
          taskList={this.state.taskList}
          taskName={this.state.taskName}
          handleTaskChange={this.handleTaskChange}
          subAccName={this.state.subAccName}
          taskCost={this.state.taskCost}
          handleTabChange={this.handleTabChange}
          handlemodaltoggle={this.toggleeditmodal}
        />
      );
    }
    return (
      <div>
        {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
        {this.state.isEditable && (
          <section className={Style.button__display}>
            <Button
              onClick={this.toggleCreateNewEquipment}
              startIcon={<Add />}
              size="large"
            >
              Add Equipments
            </Button>
          </section>
        )}
        <Grid container justify="center" className="pl-2 pr-2 mt-2">
          <Grid item>
            {this.state.equipmentData !== undefined && (
              <MUIDataTable
                title={`Equipments`}
                data={this.state.equipmentData}
                columns={columns}
                options={{
                  selectableRows: "none",
                  responsive: "verticle",
                  pagination: false,
                  download: false,
                  viewColumns: false,
                  filter: true,
                  print: false,
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}
Display.propTypes = {
  equipment: PropTypes.object.isRequired,
};

export default Display;