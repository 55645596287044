import React, { Component } from "react";
import { Button, CardHeader, Typography, Grid } from "@material-ui/core";
import ToDo from "./ToDo";
import Loading from "../../Components/Loading";
import AddToDo from "./AddToDo";
// import { blue } from "@material-ui/core/colors";
import { Add } from "@material-ui/icons";
import Style from "./style.module.scss";
import { connect } from "react-redux";
import {
  addTodo,
  getTodo,
  deleteTodo,
  getTodoMonthWise,
} from "../../Redux/todoRedux/todoAction";
import { Badge } from "@material-ui/core";

import { Link } from "react-router-dom";
import Notifications from "react-notification-system-redux";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, Calendar } from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";

class DisplayToDo extends Component {
  constructor(props) {
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const TodayDate =
      new Date().getFullYear() +
      "-" +
      (parseInt(new Date().getMonth()) + 1).toString().padStart(2, "0") +
      "-" +
      new Date().getDate().toString().padStart(2, "0");
    this.state = {
      taskTitle: "",
      taskTitleError: false,
      taskDetail: "",
      taskDetailError: false,
      assignUsers: [],
      viewUsers: [],
      deadline: new Date(),
      todoId: "",
      allTodos: [],
      isCreateNewTodo: false,
      isEditable: [],
      getTodoDate: TodayDate,
      selectedDays: [],
      assignUsersError: false,
      viewUsersError: false,
    };

    this.onMonthViewChange(new Date());
  }

  async componentDidMount() {
    await this.props.getTodo(this.state.getTodoDate);
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleChangeUser = (event, values) => {
    let newId = [];
    for (let value of values) {
      newId.push({
        UserName: value.firstName + " " + value.lastName,
        UserId: value._id,
      });
    }

    this.setState({
      assignUsers: newId,
    });
  };
  handleChangeViewUser = (event, values) => {
    let newId = [];
    for (let value of values) {
      newId.push({
        UserName: value.firstName + " " + value.lastName,
        UserId: value._id,
      });
    }

    this.setState({
      viewUsers: newId,
    });
  };

  handleChangeDate = async event => {
    console.log(this.convert(event));
    await this.props.getTodo(this.convert(event).toString());
  };
  handleChangeDeadlineDate = event => {
    this.setState({
      deadline: event,
    });
  };
  toggleCreateNewTodo = () => {
    this.setState({
      taskTitle: "",
      taskDetail: "",
      viewUsers: [],
      assignUsers: [],
      deadline: new Date(),
      isCreateNewTodo: !this.state.isCreateNewTodo,
    });
  };

  deleteTodo = id => {
    const requiredTodo = this.props.todo.data.find(eachTodo => {
      return eachTodo.todoId === id;
    });
    //
    this.props.deleteTodo(requiredTodo._id);
  };

  saveTodo = (event) => {
    event.preventDefault();
    const id = Date.now();
    let now = this.state.deadline;
    const deadDate =
      now.getFullYear() +
      "-" +
      (parseInt(now.getMonth()) + 1).toString().padStart(2, "0") +
      "-" +
      now.getDate().toString().padStart(2, "0");
    const createdDate =
      new Date().getFullYear() +
      "-" +
      (parseInt(new Date().getMonth()) + 1).toString().padStart(2, "0") +
      "-" +
      new Date().getDate().toString().padStart(2, "0");
  
    let assignUsersIds = this.state.assignUsers.map(function (item) {
      return item["UserId"];
    });
    let viewUsersIds = this.state.viewUsers.map(function (item) {
      return item["UserId"];
    });
    let assignUsers = this.state.assignUsers.map(function (item) {
      return item["UserName"];
    });
    let viewUsers = this.state.viewUsers.map(function (item) {
      return item["UserName"];
    });
  
    let assignUsersError = false;
    let viewUsersError = false;
    let taskTitleError = false;
    let taskDetailError = false;
    const trimmedTaskTitle = this.state.taskTitle.trim();
    const trimmedTaskDetail = this.state.taskTitle.trim();
    if (assignUsersIds.length === 0) {
      assignUsersError = true;
    }
    if (viewUsersIds.length === 0) {
      viewUsersError = true;
    }
    if(this.state.taskTitle.length === 0 || trimmedTaskTitle === ""){
      taskTitleError= true;
      this.setState({
        taskTitle: ""
      })
    }
    if(this.state.taskDetail.length === 0 || trimmedTaskDetail === ""){
      taskDetailError= true
      this.setState({
        taskDetail: ""
      })
    }
    const finalTodo = {
      taskTitle: this.state.taskTitle,
      taskDetail: this.state.taskDetail,
      assignUsersIds: assignUsersIds,
      viewUsersIds: viewUsersIds,
      assignUsers: assignUsers,
      viewUsers: viewUsers,
      deadline: deadDate,
      createdOn: createdDate,
      todoId: id,
      completed: false,
    };
  
    if (assignUsersError || viewUsersError || taskTitleError || taskDetailError) {
      this.setState({
        assignUsersError: assignUsersError,
        viewUsersError: viewUsersError,
        taskDetailError: taskDetailError,
        taskTitleError:taskTitleError,
      });
    } else {
      this.setState({
        taskTitle: "",
        taskDetail: "",
        assignUsers: [],
        viewUsers: [],
        isCreateNewTodo: !this.state.isCreateNewTodo,
        assignUsersError: false,
        viewUsersError: false,
      });
      this.props.addTodo(finalTodo);
    }
  };
  
  handleRemark = async (remark, id) => {
    var todo = this.props.todo.data.find(item => item.todoId === id);
    const change = {
      ...todo,
      remark: remark,
    };
    this.props.updateTodo(todo._id, change);
  };
  theme = createMuiTheme({
    palette: {
      primary: {
        main: "#345BAE",
      },
    },
    typography: {
      allVariants: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
    },
  });

  convert = str => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  onMonthViewChange = async date => {
    var firstDay = this.convert(
      new Date(date.getFullYear(), date.getMonth(), 1)
    );
    var lastDay = this.convert(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    );

    this.props.getTodoMonthWise(firstDay, lastDay);
  };
  render() {
    const { isCreateNewTodo } = this.state;
    if (isCreateNewTodo) {
      return (
        <AddToDo
          handleChange={this.handleChange}
          handleChangeUser={this.handleChangeUser}
          handleChangeViewUser={this.handleChangeViewUser}
          handleChangeDeadlineDate={this.handleChangeDeadlineDate}
          taskTitle={this.state.taskTitle}
          taskDetail={this.state.taskDetail}
          assignUsers={this.state.assignUsers}
          deadline={this.state.deadline}
          saveTodo={this.saveTodo}
          toggleCreateNewTodo={this.toggleCreateNewTodo}
          viewUsersError={this.state.viewUsersError}
          assignUsersError={this.state.assignUsersError}
          taskTitleError= {this.state.taskTitleError}
          taskDetailError= {this.state.taskDetailError}
        />
      );
    }
    return (
      <div>
        {console.log(this.props.todo.monthdata.data)}
        {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
        <div className="container" style={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {this.state.isEditable && (
                <section className={Style.button__display}>
                  <Button
                    justifyContent="center"
                    alignItems="center"
                    fullWidth
                    onClick={this.toggleCreateNewTodo}
                    startIcon={<Add />}
                    size="large"
                  >
                    Add Todo
                  </Button>
                </section>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiThemeProvider theme={this.theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Paper style={{ overflow: "hidden" }}>
                    <DatePicker
                      autoOk
                      variant="static"
                      openTo="date"
                      value={this.state.getTodoDate}
                      onYearChange={this.onMonthViewChange}
                      onMonthChange={this.onMonthViewChange}
                      onChange={date => {
                        this.setState({
                          getTodoDate: date,
                        });
                        this.handleChangeDate(new Date(date).toISOString());
                      }}
                      renderDay={(
                        day,
                        selectedDate,
                        isInCurrentMonth,
                        dayComponent
                      ) => {
                        let bool = this.props.todo.monthdata.data
                          ? this.props.todo.monthdata.data.indexOf(
                              this.convert(day)
                            ) >= 0
                          : false;
                        if (bool) {
                          return (
                            <div style={{ backgroundColor: "#dddd" }}>
                              <Badge badgeContent={"🌚"}>{dayComponent}</Badge>;
                            </div>
                          );
                        }
                        return dayComponent;
                      }}
                    />
                  </Paper>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </Grid>
            <Grid
            className={Style.all_tododiv}
              style={{
                display:"flex",
                backgroundColor: "#fff",
              }}
              item
              xs={12}
              md={6}
            >
              {this.props.loading ? (
                <Loading loading={this.props.loading} />
              ) : !this.props.loading && !this.props.todo.data.length ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    style={{ marginLeft: "0.5em", textAlign: "center" }}
                  >
                    No Task Found!
                  </Typography>
                </div>
              ) : (
                <div>
                  <section className={Style.all__todo}>
                    {this.props.todo.data.map((eachTodo, index) => {
                      let todoId = eachTodo.todoId;
                      return (
                        <div key={index} className={Style.card}>
                          <ToDo
                            isEditable={eachTodo.isEditable}
                            id={eachTodo.todoId}
                            taskTitle={eachTodo.taskTitle}
                            taskDetail={eachTodo.taskDetail}
                            assignUsers={eachTodo.assignUsers}
                            viewUsers={eachTodo.viewUsers}
                            deadline={eachTodo.deadline}
                            completed={eachTodo.completed}
                            deleteTodo={this.deleteTodo}
                          />
                        </div>
                      );
                    })}
                  </section>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

DisplayToDo.propTypes = {
  todo: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    todo: state.todoReducer,
    notifications: state.notifications,
    success: state.todoReducer.success,
    loading: state.todoReducer.loading,
    monthData: state.todoReducer.monthdata,
  };
};

export default connect(mapStateToProps, {
  addTodo,
  getTodo,
  getTodoMonthWise,
  deleteTodo,
})(DisplayToDo);
