import {
  LOADING_ADD_CHECKLIST,
  ERROR_ADD_CHECKLIST,
  SUCCESS_ADD_CHECKLIST,
  LOADING_GET_CHECKLIST,
  SUCCESS_GET_CHECKLIST,
  ERROR_GET_CHECKLIST,
  SUCCESS_GET_DEFAULT_CHECKLIST,
  SUCCESS_UPDATE_DEFAULT_CHECKLIST,
  LOADING_UPDATE_CHECKLIST,
} from "./checklistActionTypes";
import axios from "axios";
import Notifications from "react-notification-system-redux";

const getSuccessNotification = msg => {
  return {
    title: "Success",
    message: msg,
    position: "tr",
    autoDismiss: 3,
  };
};

const errorNotificationOpts = {
  title: "Error",
  message: "Something went wrong!! Please try again",
  position: "tr",
  autoDismiss: 3,
};

// DEFAULT CHECKLIST
export const getDefaultChecklist = type => async dispatch => {
  dispatch({
    type: LOADING_GET_CHECKLIST,
  });
  // //
  try {
    const projectId = global.config.secureStorage.getItem("projectId");
    const superProject = global.config.secureStorage.getItem("superProject");
    const response = await axios.get(
      `${global.config.backendURL}/checklist/getDefaultChecklist/${superProject}/${type}`
    );
    //
    dispatch({
      type: SUCCESS_GET_DEFAULT_CHECKLIST,
      payload: response.data,
    });
  } catch (error) {
    //
    dispatch({
      type: ERROR_GET_CHECKLIST,
      error: error.message,
    });
  }
};

export const updateDefaultChecklist =
  (id, checklist, type) => async dispatch => {
    dispatch({
      type: LOADING_UPDATE_CHECKLIST,
    });
    try {
      let newChecklist = {};
      const projectId = await global.config.secureStorage.getItem("projectId");
      const superProject = await global.config.secureStorage.getItem("superProject");

      newChecklist["data"] = checklist;
      newChecklist["projectId"] = projectId;
      newChecklist["type"] = type;
      newChecklist['superProject']=superProject
      const response = await axios.post(
        `${global.config.backendURL}/checklist/updateDefaultChecklist/${id}`,
        newChecklist
      );
      dispatch({
        type: SUCCESS_UPDATE_DEFAULT_CHECKLIST,
        payload: response.data,
      });
      dispatch(getDefaultChecklist(type));
    } catch (error) {
      //
      dispatch({
        type: ERROR_GET_CHECKLIST,
        error: error.message,
      });
    }
  };
  export const deleteChecklist =
  (id,defChecklistTodeleteId,type) => async dispatch => {
    dispatch({
      type: LOADING_UPDATE_CHECKLIST,
    });
    try {
      const projectId = await global.config.secureStorage.getItem("projectId");
      let data ={}
      data['defChecklistTodeleteId']=defChecklistTodeleteId
      data['projectId']=projectId
      data["type"] = type;
      const response = await axios.post(
        `${global.config.backendURL}/checklist/deleteChecklist/${id}`,
        data
      );
      dispatch(getDefaultChecklist(type));
    } catch (error) {
      //
      dispatch({
        type: ERROR_GET_CHECKLIST,
        error: error.message,
      });
    }
  };
// NEW CHECKLIST
export const addChecklist = newChecklist => async dispatch => {
  dispatch({ type: LOADING_ADD_CHECKLIST });
  const projectId = await global.config.secureStorage.getItem("projectId");

  newChecklist["subprojectId"] =
    global.config.secureStorage.getItem("subprojectId");
  try {
    const response = await axios.post(
      `${global.config.backendURL}/checklist/addChecklist`,
      { item: newChecklist, projectId }
    );
    // dispatch({ type: SUCCESS_ADD_CHECKLIST });
    dispatch(
      fetchCheckList({
        subprojectId: newChecklist["subprojectId"],
        type: newChecklist["type"],
        subActivity: newChecklist["activityName"],
      })
    );
    dispatch(Notifications.success(getSuccessNotification("Checklist Added!")));
  } catch (error) {
    console.log("add checlist error", error);
    dispatch({ type: ERROR_ADD_CHECKLIST });
    dispatch(Notifications.error(errorNotificationOpts));
    //
  }
};

export const fetchCheckList =
  (data, notify = false) =>
  async dispatch => {
    dispatch({ type: LOADING_GET_CHECKLIST });
    //
    try {
      const res = await axios.post(
        `${global.config.backendURL}/checklist/getCheckListBySubProjectId`,
        data
      ); 
      // //
      let noOfSubmitted = 0;
      let noOfChecked = 0;
      let noOfApproved = 0;
      console.log("fetch checlist data is ",res.data,data)
      res.data.forEach(task => {
        if (!task.approved && !task.checked) {
          noOfSubmitted = noOfSubmitted + 1;
        } else if (!task.approved && task.checked) {
          noOfChecked = noOfChecked + 1;
        } else if (task.approved && task.checked) {
          noOfApproved = noOfApproved + 1;
        }
      });
      dispatch({
        type: SUCCESS_GET_CHECKLIST,
        payload: res.data,
        noOfSubmitted,
        noOfApproved,
        noOfChecked,
      });
      if (notify) {
        dispatch(
          Notifications.success(getSuccessNotification("Checklist Approved!"))
        );
      }
    } catch (error) {
      console.log("fetch checklist error", error);
      dispatch({ type: ERROR_GET_CHECKLIST });
    }
    // }
  };

export const approveCheckList =
  (id, subprojectId, subActivity, approveImage, type, forapproval) =>
  async dispatch => {
    //
    dispatch({ type: LOADING_GET_CHECKLIST });
    const projectId = await global.config.secureStorage.getItem("projectId");

    try {
      if (forapproval) {
        await axios.post(
          `${global.config.backendURL}/checklist/approveCheckList/`,
          {
            id: id,
            subprojectId: subprojectId,
            forapproval: true,
            approveImage,
            projectId,
          }
        );
      } else {
        await axios.post(
          `${global.config.backendURL}/checklist/checkChecklist/`,
          {
            id: id,
            subprojectId: subprojectId,
            forapproval: false,
            projectId,
          }
        );
      }
      console.log("tyep is ", type);
      if (type)
        dispatch(
          fetchCheckList(
            {
              subprojectId,
              subActivity,
              type,
            },
            true
          )
        );
    } catch (error) {
      dispatch({ type: ERROR_GET_CHECKLIST });
    }
  };
