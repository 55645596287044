import React, { Component } from "react";
import Swal from 'sweetalert2';
import Loading from "../../Components/Loading";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import {
  clearActivityCheckedArray,
  getTaskActivityDetails,
  updateTaskActivityDetails,
  doneUpdating,
} from "../../Redux/activityRedux/activityAction";
import {
  TableBody,TableHead,TableCell,TableContainer,TableRow,Accordion,AccordionSummary,AccordionDetails,Card,CardContent,Grid,Typography, Button,withStyles,Radio, RadioGroup,FormControlLabel,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";

import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Add as AddIcon,
  Edit,
  Delete,
} from "@material-ui/icons";

import axios from "axios";
import AddTasks from "./Tasks/AddTasks";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class SubActivities extends Component {
  constructor(props) {
    super(props);

    const role = global.config.secureStorage.getItem("role");
    const roles = [
      "project_manager",
      "planning_manager",
      "senior_engineer",
      "chief_engineer",
      "junior_engineer",
      "super_admin",
    ];
    const isAddVisible = roles.find(item => item == role) ? true : false;
    this.state = {
      activityDetails: [],
      taskName: "",
      tasknameError: false,
      taskcostError: false,
      taskcost: "",
      taskEstimateStart: "",
      taskEstimateEnd: "",
      taskSubName: "",
      taskSubNameError: false,
      taskSubQuantity: "",
      taskSubUnit: "",
      isAddVisible: isAddVisible,
      modalOpen: false,
      subProject: {},
      loading: true,
      taskPercentage: [],
      SubtaskPercentage: [],
      expanded: "panel1",
      isSubTask: false,
      isEdit: false,
      taskIndex: 0,
      isUpdated: false,
      showDates: "est",
      error: "",
      taskSameNameError: "",
    };
  }

  async componentDidMount() {
    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const projectId = global.config.secureStorage.getItem("projectId");

    const { subactivity } = this.props.match.params;
    try {
      this.props.clearActivityCheckedArray();
      this.props.doneUpdating();
      const subProjectDetails = await axios.get(
        `${global.config.backendURL}/project/getSingleSubProject/${subprojectId}`
      );
      const floorPerc = await axios.get(
        `${global.config.backendURL}/activities/getTaskPercentage/${projectId}/${subprojectId}/${subactivity}`
      );
      this.setState({
        subProject: subProjectDetails.data,
        loading: false,
        taskPercentage: floorPerc.data.task,
        SubtaskPercentage: floorPerc.data.subtask,
      });
    } catch (error) {}
  }
  async componentDidUpdate() {
    const path = this.props.match.params;

    const { subactivity } = this.props.match.params;
    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const projectId = global.config.secureStorage.getItem("projectId");
    if (!this.state.isUpdated) {
      await this.props.getTaskActivityDetails({
        activity: path.subactivity,
        subprojectId: subprojectId,
      });

      this.setState({
        activityDetails: this.props.activityDetails,
        isUpdated: true,
      });
    }
  }

  fetchPercentage = index => {
    let temptaskPercentage = [...this.state.taskPercentage];
    if (temptaskPercentage != undefined) {
      if (temptaskPercentage[index]) {
        return temptaskPercentage[index];
      }
    }
    return 0;
  };
  fetchPercentageSubTask = (index, subindex) => {
    let temptaskPercentage = [...this.state.SubtaskPercentage];
    if (temptaskPercentage != undefined) {
      if (temptaskPercentage[index] != undefined) {
        if (temptaskPercentage[index][subindex]) {
          return parseInt(temptaskPercentage[index][subindex]);
        }
      }
    }
    return 0;
  };
  handleChange = panel => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  handleModalOpen = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      isEdit: false,
      taskEstimateEnd: "",
      taskEstimateStart: "",
      isSubTask: false,
    });
    this.setState({
      tasknameError: false,
      taskcostError: false,
      taskSubNameError: false,
    })
  };
  handletaskSameNameError = value => {
    let arrayAcc = [...this.state.activityDetails];
    value = value.trim();
    if (!this.state.isSubTask) {
      let sameNameFound = false;
      if (arrayAcc)
        arrayAcc.forEach(task => {
          if (task.name === value) {
            this.setState({
              taskSameNameError: "task name already taken",
            });
            sameNameFound = true;
            return;
          }
        });
      if (!sameNameFound) {
        this.setState({
          taskSameNameError: "",
        });
      }
    } else {
      let sameNameFound = false;
      let subArr = arrayAcc[this.state.taskIndex].subTask;
      if (subArr)
        subArr.forEach(task => {
          if (task.name === value) {
            this.setState({
              taskSameNameError: "Subtask name already taken",
            });
            sameNameFound = true;
            return;
          }
        });
      if (!sameNameFound) {
        this.setState({
          taskSameNameError: "",
        });
      }
    }
  };
  handleTaskChange = (value, name) => {
    console.log(name, value);
    this.setState({
      [name]: value,
    });
  };
  handleEdit = (i, name) => {
    let arrayAcc = [...this.state.activityDetails];
    this.setState({
      taskEstimateStart: arrayAcc[i].taskEstimateStart,
      taskEstimateEnd: arrayAcc[i].taskEstimateEnd,
      modalOpen: true,
      isEdit: true,
      taskName: name,
      taskcost:parseInt(arrayAcc[i].taskCost),
      taskIndex: i,
    });
  };
  handleSubtask = (i, name, quantity, unit) => {
    this.setState({
      modalOpen: true,
      taskName: name,
      taskEstimateStart: quantity,
      taskEstimateEnd: unit,
      isSubTask: true,
      taskIndex: i,
    });
  };
  handleCostChange = (event, index) => {
    console.log("event target value", event.target.value, index);
    console.log("this.state is ", this.state.activityDetails);
  };
  submitTask = () => {
    console.log("this state task cost is ", this.state.taskcost);
    let tasknameError= false;
    let taskcostError= false;

    if (this.state.taskName === "" || this.state.taskName.trim() === "") {
      tasknameError = true;
      this.setState({
        taskName:""
      })
    }
    if (this.state.taskcost === "" || this.state.taskcost.trim() === "") {
      taskcostError = true;
    }

    if(tasknameError || taskcostError){
      this.setState({
        tasknameError: tasknameError,
        taskcostError: taskcostError,
      })
    }

    else{
      this.setState({
        tasknameError: tasknameError,
        taskcostError: taskcostError,
      })
      if (this.state.taskSameNameError.length > 0 || this.state.taskcost === "")
      return;
    let arrayAcc = [...this.state.activityDetails];
    if (!this.state.isEdit) {
      let tempStart = "";
      if (arrayAcc[this.state.taskIndex]) tempStart = "";

      arrayAcc[arrayAcc.length] = {
        name: this.state.taskName,
        taskCost: this.state.taskcost,
        taskEstimateStart: this.state.taskEstimateStart,
        taskEstimateEnd: this.state.taskEstimateEnd,
        taskActualStart: tempStart,
        taskActualEnd: "",
      };
    } else {
      arrayAcc[this.state.taskIndex] = {
        name: this.state.taskName,
        taskCost: this.state.taskcost,
        subTask: arrayAcc[this.state.taskIndex].subTask,
        taskEstimateStart:
          this.state.taskEstimateStart != null
            ? this.state.taskEstimateStart
            : arrayAcc[this.state.taskIndex].taskEstimateStart,
        taskEstimateEnd:
          this.state.taskEstimateEnd != null
            ? this.state.taskEstimateEnd
            : arrayAcc[this.state.taskIndex].taskEstimateEnd,
        taskActualStart:
          arrayAcc[this.state.taskIndex].taskActualStart != ""
            ? arrayAcc[this.state.taskIndex].taskActualStart
            : "",
        taskActualEnd:
          arrayAcc[this.state.taskIndex].taskActualEnd != ""
            ? arrayAcc[this.state.taskIndex].taskActualEnd
            : "",
      };
    }

    if (
      this.state.taskEstimateStart != "" &&
      this.state.taskEstimateEnd != ""
    ) {
      this.updateCondition(arrayAcc);
      window.location.reload();
      this.handleModalOpen();
      this.setState({
        taskIndex: 0,
        activityDetails: [],
        taskName: "",
      });
    } else {
      this.setState({
        error: "Field is Empty",
      });
    }
    }
  };

  guidGenerator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  };
  submitSubTask = () => {
    let taskSubNameError= false;

    if(this.state.taskSubName === "" || this.state.taskSubName.trim() === ""){
      taskSubNameError= true
    }
    if(taskSubNameError){
      this.setState({
        taskSubNameError: taskSubNameError,
      })
    }
    else{
      this.setState({
        taskSubNameError: false,
      })
      if (this.state.taskSameNameError.length > 0) return;
      let arrayAcc = [...this.state.activityDetails];
      let randomid = this.guidGenerator();
      let subArr = [];
      subArr = arrayAcc[this.state.taskIndex].subTask;
      if (subArr != undefined) {
        subArr[subArr.length] = {
          name: this.state.taskSubName,
          percentage: 0,
          randomid,
        };
      } else {
        subArr = [
          {
            name: this.state.taskSubName,
            percentage: 0,
            randomid,
          },
        ];
      }
      console.log("array acc for task cost is ",arrayAcc[this.state.taskIndex])
      arrayAcc[this.state.taskIndex] = {
        name: this.state.taskName,
        taskCost:arrayAcc[this.state.taskIndex].taskCost,
        taskEstimateStart: arrayAcc[this.state.taskIndex].taskEstimateStart,
        taskEstimateEnd: arrayAcc[this.state.taskIndex].taskEstimateEnd,
        taskActualStart:
          arrayAcc[this.state.taskIndex].taskActualStart != undefined
            ? arrayAcc[this.state.taskIndex].taskActualStart
            : "",
        taskActualEnd: "",
        subTask: subArr,
      };
  
      this.updateCondition(arrayAcc);
      window.location.reload();
      this.handleModalOpen();
      this.setState({
        taskIndex: 0,
        isSubTask: false,
        activityDetails: [],
  
        taskName: "",
        taskcost: "",
        taskEstimateStart: "",
        taskEstimateEnd: "",
  
        taskSubName: "",
        taskSubQuantity: "",
        taskSubUnit: "",
      });
    }
  };

  deleteTask = i => {
    let arrayAcc = [...this.state.activityDetails];

    arrayAcc.splice(i, 1);
    this.updateCondition(arrayAcc);
  };
  deleteSubTask = (i, subindex) => {
    let arrayAcc = [...this.state.activityDetails];
    let subArr = [];
    subArr = arrayAcc[i].subTask;
    subArr.splice(subindex, 1);

    arrayAcc[i].subTask = subArr;
    this.updateCondition(arrayAcc);
  };

  updateCondition = arrayAcc => {
    const { activity, subactivity, floorNumber, subTaskIndex } =
      this.props.match.params;

    const subprojectId = global.config.secureStorage.getItem("subprojectId");

    if (subactivity === "null") {
      this.props.updateTaskActivityDetails({
        activity: activity,
        checkedArray: arrayAcc,
        subprojectId,
      });
    } else {
      this.props.updateTaskActivityDetails({
        activity: subactivity,
        checkedArray: arrayAcc,
        subprojectId,
      });
    }

    this.setState({
      isUpdated: false,
    });
  };
  handleSelectChange = e => {
    if (e.target.value == "ActualDates") {
      this.state.showDates = "act";
    } else {
      this.state.showDates = "est";
    }
    this.setState({
      isUpdated: false,
    });
  };
  render() {
    const path = this.props.match.params;
    const activity = path.activity;
    const subactivity = path.subactivity;
    let finalActivity = "";
    if (this.state.loading) return <Loading loading={this.state.loading} />;
    if (subactivity === "null")
      return <div>Null Activity please add activity</div>;
    if (subactivity != "null")
      return (
        <div>
          <div>
            <Typography variant="h6" style={{ padding: "10px 20px", textTransform:"capitalize" }}>
              Activities/{path.activity}/
              {path.subactivity}
            </Typography>
          </div>
          <div>
            <AddTasks
              taskSameNameError={this.state.taskSameNameError}
              error={this.state.error}
              isSubTask={this.state.isSubTask}
              taskName={this.state.taskName}
              tasknameError={this.state.tasknameError}
              taskEstimateStart={this.state.taskEstimateStart}
              taskEstimateEnd={this.state.taskEstimateEnd}
              taskcost={this.state.taskcost}
              taskcostError={this.state.taskcostError}
              taskSubName={this.state.taskSubName}
              taskSubNameError={this.state.taskSubNameError}
              taskSubQuantity={this.state.taskSubQuantity}
              taskSubUnit={this.state.taskSubUnit}
              handleModalOpen={this.handleModalOpen}
              handleTaskChange={this.handleTaskChange}
              submitTask={this.submitTask}
              submitSubTask={this.submitSubTask}
              isEdit={this.state.isEdit}
              open={this.state.modalOpen}
              handletaskSameNameError={this.handletaskSameNameError}
            />
          </div>
          {this.state.isAddVisible && (
            <Grid
              container
              direction="row"
              style={{ marginVertical: "2em" }}
              justify="center"
            >
              <Grid item>
                <Button
                  className="m-2"
                  color="primary"
                  onClick={this.handleModalOpen}
                  startIcon={<AddIcon />}
                >
                  Add Tasks
                </Button>
              </Grid>
            </Grid>
          )}
          <div className="container" style={{ marginTop: "20px" }}>
            {this.state.activityDetails != undefined &&
            this.state.activityDetails.length !== 0
              ? this.state.activityDetails.map((task, index) => {
                  return (
                    <Accordion
                      expanded={this.state.expanded === `panel${index + 1}`}
                      onChange={this.handleChange(`panel${index + 1}`)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              flexGrow: 1,
                            }}
                          >
                            <Typography variant="h6">{task.name}</Typography>
                            <Typography
                              style={{ marginRight: "20px" }}
                              color="textSecondary"
                              variant="h6"
                            >
                              {this.fetchPercentage(index).toFixed(2)}%
                            </Typography>
                          </div>
                          {this.state.isAddVisible && (
                            <div>
                              <Grid item>
                                <IconButton
                                  aria-label="settings"
                                  onClick={() => {
                                    this.handleEdit(
                                      index,
                                      task.name,
                                      task.quantity,
                                      task.unit
                                    );
                                  }}
                                  style={{
                                    background: "#345BAE",
                                    color: "white",
                                    border: "1px solid transparent",
                                    borderRadius: "3px",
                                    padding: "4px",
                                    fontSize: "1rem",
                                    marginRight: 4,
                                  }}
                                >
                                  <Edit  style={{width: ".75em", height:".75em", marginRight:"2px"}} />
                                  Edit
                                </IconButton>
                                <IconButton
                                  aria-label="settings"
                                  onClick={() => {
                                    Swal.fire({
                                      title: 'Are you sure?',
                                      text: "You won't be able to revert this!",
                                      icon: 'warning',
                                      showCancelButton: true,
                                      confirmButtonColor: '#3085d6',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: 'Yes, delete it!'
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        this.deleteTask(index);
                                        Swal.fire(
                                          'Deleted!',
                                          'Your file has been deleted.',
                                          'success'
                                        )
                                      }
                                    })
                                    
                                  }}
                                  style={{
                                    background:"#F24236",
                                    color: "white",
                                    border: "1px solid transparent",
                                    borderRadius: "3px",
                                    padding: "4px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  <Delete  style={{width: ".75em", height:".75em"}} />
                                  Delete
                                </IconButton>
                              </Grid>
                            </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: "inline" }}>
                        <Grid
                          container
                          spacing={0}
                          alignItems="center"
                          justify="center"
                          style={{
                            marginBottom: 20,
                            padding: 20,
                          }}
                        >
                          <Grid item lg={6}>
                            <TableContainer
                              style={{ width: "100%", backgroundColor: "#fff" }}
                              aria-label="custom pagination table"
                            >
                              <RadioGroup
                                row
                                defaultValue="EstimatedDates"
                                color="secondary"
                                onChange={this.handleSelectChange}
                              >
                                <FormControlLabel
                                  value="EstimatedDates"
                                  label="Estimated Dates"
                                  control={<Radio color="default" />}
                                />
                                <FormControlLabel
                                  value="ActualDates"
                                  label="Actual Dates"
                                  control={<Radio color="default" />}
                                />
                              </RadioGroup>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell
                                    align="center"
                                    style={{ width: "40%" }}
                                  >
                                    {this.state.showDates == "act"
                                      ? "Task Actual Start"
                                      : "Task Estimated Start"}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    style={{ width: "40%" }}
                                  >
                                    {this.state.showDates == "act"
                                      ? "Task Actual End"
                                      : "Task Estimated End"}
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key={index}>
                                  <TableCell
                                    style={{ padding: 0 }}
                                    align="center"
                                  >
                                    <Typography align={"center"} variant="h6">
                                      {this.state.showDates == "act"
                                        ? task.taskActualStart
                                          ? moment(new Date(task.taskActualStart)).format('DD/MM/YYYY')
                                              .split("T")[0]
                                          : "Not Defined"
                                        : task.taskEstimateStart
                                        ? moment(new Date(task.taskEstimateStart)).format('DD/MM/YYYY')
                                            .split("T")[0]
                                        : "Not Defined"}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: 0 }}
                                    align="center"
                                  >
                                    <Typography align={"center"} variant="h6">
                                      {this.state.showDates == "act"
                                        ? task.taskActualStart
                                          ? task.taskActualEnd
                                            ? moment(new Date(task.taskActualEnd)).format('DD/MM/YYYY')
                                                .split("T")[0]
                                            : "Not Defined"
                                          : "Not Defined"
                                        : task.taskEstimateEnd
                                        ? moment(new Date(task.taskEstimateEnd)).format('DD/MM/YYYY')
                                            .split("T")[0]
                                        : "Not Defined"}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </TableContainer>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={0}
                          alignItems="center"
                          justify="center"
                          style={{
                            marginBottom: 20,
                            padding: 20,
                          }}
                        >
                          <Grid item lg={4}>
                            <Typography
                            style={{ marginRight: "20px" }}
                            color="textSecondary"
                            variant="h6"
                            >cost :{task.taskCost}</Typography>
                          </Grid>
                        </Grid>
                        {this.state.isAddVisible && (
                          <Grid
                            container
                            direction="row"
                            style={{ marginTop: "2em" }}
                            justify="flex-end"
                          >
                            <Grid item>
                              <Button
                                className="m-2"
                                color="primary"
                                onClick={() => {
                                  this.handleSubtask(
                                    index,
                                    task.name,
                                    task.quantity,
                                    task.unit
                                  );
                                }}
                                startIcon={<AddIcon />}
                              >
                                Add Sub Tasks
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {task.subTask
                          ? task.subTask.map((subTask, subTaskIndex) => {
                              return (
                                <Grid style={{ display: "flex", alignItems:"center", marginBottom: ".5rem" }}>
                                  <Link
                                    key={subTaskIndex}
                                    style={{ textDecoration: "none", flex: 1 }}
                                    to={{
                                      pathname: `/activities/${path.activity}/${path.subactivity}/${index}/${subTaskIndex}`,
                                    }}
                                  >
                                    <Card
                                      style={{
                                        boxShadow:
                                          "0 2px 4px rgb(179, 179, 179)",
                                        marginLeft: 20,
                                        marginRight: 20,
                                      }}
                                      className=""
                                      variant="outlined"
                                    >
                                      <CardContent style={{padding:"10px"}}>
                                        <Grid
                                          container
                                          justify="space-between"
                                          alignItems="center"
                                          direction="row"
                                        >
                                          <Grid item xs={8}>
                                            <Typography
                                              variant="h6"
                                              component="h2"
                                            >
                                              {subTask.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography
                                              color="textSecondary"
                                            >
                                              {this.fetchPercentageSubTask(
                                                index,
                                                subTaskIndex
                                              ).toFixed(2)}
                                              %
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Link>
                                  <IconButton
                                    aria-label="settings"
                                    onClick={() => {
                                      Swal.fire({
                                        title: 'Are you sure?',
                                        text: "You won't be able to revert this!",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes, delete it!'
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          this.deleteTask(index);
                                          Swal.fire(
                                            'Deleted!',
                                            'Your file has been deleted.',
                                            'success'
                                          )
                                          this.deleteSubTask(index, subTaskIndex);
                                        }
                                      })
                                     
                                    }}
                                    style={{
                                      backgroundColor: "red",
                                      color: "#fff",
                                      padding: "11px",
                                      height: "35px",
                                      margin: 5,
                                      borderRadius:"3px",
                                      fontSize:"1rem"
                                    }}
                                  >
                                    <Delete style={{width: ".65em", height:".65em"}} />
                                    Delete
                                  </IconButton>
                                </Grid>
                              );
                            })
                          : ""}
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              : ""}
          </div>
        </div>
      );
  }
}

const mapStateToProps = state => {
  //
  return {
    activityDetails: state.activityReducer.activityDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTaskActivityDetails: data => dispatch(getTaskActivityDetails(data)),
    updateTaskActivityDetails: data =>
      dispatch(updateTaskActivityDetails(data)),
    clearActivityCheckedArray: () => dispatch(clearActivityCheckedArray()),
    doneUpdating: () => dispatch(doneUpdating()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubActivities);