import React, { useState, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";
import {
  InputLabel,
  TextField,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Tabs, Tab, AppBar
} from "@material-ui/core";
import { Save, Cancel, Add as AddIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Style from "./style.module.scss";
import { isInteger } from "lodash";
import axios from "axios";

const EditEquipment = props => {
  const [open, setOpen] = useState(false);
  const [equipment, setequipment] = useState([]);
  const [txt, setTxt] = useState("");
  const [toggleText, setToggleText] = useState(false);
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState("");
  const [mainActivities, setMainActivities] = useState([]);
  const [projectType, setProjectType] = useState("");
  const [equipmentlist,setEquipmentlist]=useState([])
  const[ tabIndex,setTabIndex]=useState(0)
  const loading = open && equipment.length === 0;
 useEffect(() => {
getMasterEquipmentList()
console.log("props of equipments are ",props)
 },[])
  async function getMasterEquipmentList(){
    let projectId = await global.config.secureStorage.getItem("projectId");
    const response = await axios.get(
      `${global.config.backendURL}/stores/getMasterListEquipments/${projectId}`
    );
    console.log("ressponse is ",response)
    let equipment_list=[];
    response.data.forEach(element => {
        equipment_list.push(element.equipmentName)
    });
    setEquipmentlist(equipment_list)
  }
  const handelTabs = (e, val) => {
    setTabIndex(val);
    // props.handleTabChange()
  }
  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    var tempMaterials = new Set();
    (() => {
      props.storeData.map(ele => {
        return tempMaterials.add(ele.materialName);
      });
      setequipment(Array.from(tempMaterials));
    })();
  }, [loading]);
  useEffect(() => {
    if (!open) {
      setequipment([]);
    }
    getActivities();
  }, [open]);
  async function getActivities() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    // const response = await axios.get(
    //   `${global.config.backendURL}/activities/getDefaultActivities/${projectId}`
    // );
    // if(projectType === "Infrastructure"){
    //   const mainActivities = response.data.data.map((mainAct,index)=>{
    //     return {
    //       id: index,
    //       title : mainAct.name,
    //       activities : mainAct.data
    //     };
    //   });
    //   setMainActivities(mainActivities);
    // }
    // else{
    //   setActivities(response.data.data[0].data);
    // }
  //  setActivities(response.data.data);
  }
  //   const handleMainActivityChange = (event) =>{
  //     const value = event.target.value;
  //     const id = mainActivities.findIndex(act=>{
  //       return act.title === value;
  //     });
  //     console.log(id)
  //     if(id>=0){
  //      const activity = mainActivities[id].activities;
  //      console.log(activity);
  //      setActivities(activity);
  //      props.handleMainActivity(event);
  //     }
  //  }
  return (
    <div>
      <section className={Style.create__material}>
        <form onSubmit={props.updateEquipment}>
          <center>
            <Typography variant="overline" style={{ fontSize: "1.5em" }}>
              Edit to Equipment
            </Typography>
          </center>
          <br />
          <br />
          {/* <TextField
            value={props.equipmentName}
            margin="dense"
            name="equipmentName"
            id="equipmentName"
            type="text"
            variant="outlined"
            label="Equipment"
            fullWidth
            onKeyPress={event =>
              (event.charCode >= 65 && event.charCode <= 90) ||
              (event.charCode >= 97 && event.charCode <= 122)
            }
            onChange={event => {
              props.handleChange(event);
            }}
            required
          /> */}
          <>
          <Grid container>
              <Grid item md={12} xs={12}>
                 <FormControl fullWidth>
                 <InputLabel id="activity">Equipment</InputLabel>
                <Select
              labelId="Equipment"
              id="Equipment"
              value={props.equipmentName}
              label="Equipment"
              onChange={event => {
                props.handleEquipmentChange(event);
              }}

              required
            >
              {equipmentlist.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            </FormControl>
              </Grid>
            </Grid>
          </>
          <br />
          <br />
          {/* {projectType === "Infrastructure"?
          <>
          <FormControl fullWidth>
            <InputLabel id="activity">Main Activity</InputLabel>
            <Select
              labelId="mainActivity"
              id="mainActivity"
              value={props.mainActivity}
              label="mainActivity"
              onChange={event => {
                handleMainActivityChange(event);
              }}
              required
            >
              {mainActivities.map(item => (
                <MenuItem value={item.title}>{item.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          </>
          :''
          } */}
           <FormControl fullWidth>
              <InputLabel id="activity">Activity</InputLabel>

              <Select
                labelId="activity"
                id="activity"
                value={props.activity}
                label="activity"
                onChange={event => {
                  props.handleActivity(event);
                }}
                required
              >
                {props.activities.map((item, i) => (
                  <MenuItem value={`${item.title};${i}`}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <div 
          style={{display:"flex", justifyContent:"center"}}> 
          <AppBar position="static"style={{backgroundColor:"white",color:"blue",width:"60%"}} >
                        <Tabs value={tabIndex} onChange={handelTabs} TabIndicatorProps={{ style: { height: "2px"} }}>
                          <Tab label="Sub activities"></Tab>
                          <Tab label="Task"></Tab>
                        </Tabs>
          </AppBar>
          </div>
            {props.activityIndex !== -1 && (
          <>
           <FormControl fullWidth>
           <InputLabel id="activity">Sub activity</InputLabel>

           <Select
             labelId="subactivity"
             id="subactivity"
             value={props.subAccName}
             label="subactivity"
             onChange={event => {
               props.handleSubActivity(event);
             }}
             required
           >
             {props.activities && props.activities[props.activityIndex].content && props.activities[props.activityIndex].content.map((item,i) => (
               <MenuItem value={`${item.subTitle};${i}`}>{item.subTitle}</MenuItem>
             ))}
           </Select>
         </FormControl>
         <br />
         <br />
         </>
         )}
            {props.taskList.length > 0 && tabIndex===1 && (
              <>
                <FormControl fullWidth>
                  <InputLabel id="activity">task</InputLabel>

                  <Select
                    labelId="activity"
                    id="activity"
                    value={props.taskName}
                    label="activity"
                    onChange={event => {
                      props.handleTaskChange(event);
                    }}
                    required
                  >
                    {props.taskList.map((item, i) => (
                      <MenuItem value={`${item.name};${i}`}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <br />
                {/* <TextField
                  name="taskcost"
                  id="taskcot"
                  variant="outlined"
                  label="Cost"
                  value={props.taskCost}
                  type="number"
                  required
                  fullWidth
                  disabled
                /> */}
              </>
            )}
          <TextField
            value={props.unit}
            margin="dense"
            name="unit"
            id="unit"
            type="text"
            variant="outlined"
            label="Unit"
            fullWidth
            onKeyPress={event =>
              (event.charCode >= 65 && event.charCode <= 90) ||
              (event.charCode >= 97 && event.charCode <= 122)
            }
            onChange={e => props.handleChange(e)}
            required
          />
          <br />
          <br />
          <TextField
            name="equipmentQuantity"
            id="equipmentQuantity"
            variant="outlined"
            onChange={event => {
              props.handleChange(event);
            }}
            label="Equipment Quantity"
            value={props.equipmentQuantity}
            type="number"
            required
            fullWidth
          />
          <br />
          <br />
          <TextField
            name="equipmentRate"
            id="equipmentRate"
            variant="outlined"
            onChange={event => {
              props.handleChange(event);
            }}
            label="Rate"
            value={props.equipmentRate}
            type="number"
            required
            fullWidth
          />
          <br />
          <br />
          <TextField
            name="equipmentAmount"
            id="equipmentAmount"
            variant="outlined"
            onChange={event => {
              if (isInteger(parseInt(event.target.value))) {
                props.handleChange(event);
              }
            }}
            label="Amount"
            value={props.equipmentRate * props.equipmentQuantity}
            type="number"
            required
            fullWidth
            disabled
          />
          <br />
          <br />
          <Grid justify="center" container>
            <Grid className="mr-4" item>
              <Button
                variant="contained"
                size="large"
                type="submit"
                startIcon={<Save />}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                size="large"
                onClick={props.handlemodaltoggle}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </section>
    </div>
  );
};
export default EditEquipment;