import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ApiGetMethod } from '../../Components/apiHelper/helper';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function GroupChart({ apiresponse, selectedActivityPVA,  selectedSubActivityPVA}) {

    const options = {
        responsive: true,
        scales: {
          y:{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return value + ' Days';
              },
            },
              min: 0,
          },
          x:{
              display: true
          }
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          },
        },
      };

      let labels = apiresponse ? apiresponse.map((activity) => activity.activityName.length > 10 ? activity.activityName.substring(0, 10) + '...' : activity.activityName) : ""
      let plannedTimeData = [];
      let plannedTimeDatasub = [];
      let actualTimeData = [];
      let actualTimeDatasub = [];
      if(selectedActivityPVA != 0){
        if (selectedSubActivityPVA) {
          const selectedActivity = apiresponse.find((activity) => {
            const subActivity = activity.subActivity.find((sub) => sub.name === selectedSubActivityPVA);
            return subActivity;
          });
        
          if (selectedActivity) {
            const selectedSubActivity = selectedActivity.subActivity.find((subActivity) => subActivity.name === selectedSubActivityPVA);
            if (selectedSubActivity) {
              labels = selectedSubActivity.taskList.map((task) => task.name.length > 10 ? task.name.substring(0, 10) + '...' : task.name);
            }
          
            plannedTimeDatasub = selectedSubActivity.taskList.map((task) => {
              let totalEstimatedStart = new Date(task.taskEstimateStart).getTime();
              let totalEstimatedEnd = new Date(task.taskEstimateEnd).getTime();
      
              // selectedActivity.subActivity.forEach((subActivity) => {
              //   subActivity.taskList.forEach((subTask) => {
              //     const subTaskEstimatedStart = new Date(subTask.taskEstimateStart).getTime();
              //     const subTaskEstimatedEnd = new Date(subTask.taskEstimateEnd).getTime();
      
              //     if (subTaskEstimatedStart < totalEstimatedStart) {
              //       totalEstimatedStart = subTaskEstimatedStart;
              //     }
      
              //     if (subTaskEstimatedEnd > totalEstimatedEnd) {
              //       totalEstimatedEnd = subTaskEstimatedEnd;
              //     }
              //   });
              // });
      
              const plannedDuration = totalEstimatedEnd - totalEstimatedStart;
              let plannedHours = plannedDuration / (1000 * 60 * 60 * 24);
              plannedHours = Math.ceil(plannedHours);
              return plannedHours;
            });
      
            actualTimeDatasub = selectedSubActivity.taskList.map((task) => {
              let totalActualStart = new Date(task.taskActualStart).getTime();
              let totalActualEnd = new Date(task.taskActualEnd).getTime();
      
              // selectedActivity.subActivity.forEach((subActivity) => {
              //   subActivity.taskList.forEach((subTask) => {
              //     const subTaskActualStart = new Date(subTask.taskActualStart).getTime();
              //     const subTaskActualEnd = new Date(subTask.taskActualEnd).getTime();
      
              //     if (subTaskActualStart < totalActualStart) {
              //       totalActualStart = subTaskActualStart;
              //     }
      
              //     if (subTaskActualEnd > totalActualEnd) {
              //       totalActualEnd = subTaskActualEnd;
              //     }
              //   });
              // });
      
              const actualDuration = totalActualEnd - totalActualStart;
              let actualHours = actualDuration / (1000 * 60 * 60 * 24);
              actualHours = Math.ceil(actualHours);
              return actualHours;
            });
          }
        }else{
         labels = apiresponse ? apiresponse.map((activity) => activity.activityName.length > 10 ? activity.activityName.substring(0, 10) + '...' : activity.activityName) : ""


          plannedTimeData = apiresponse ? apiresponse.map((activity) => {
            let totalEstimatedStart = Infinity;
            let totalEstimatedEnd = 0;
      
            activity.subActivity.forEach((subActivity) => {
              subActivity.taskList.forEach((task) => {
                const taskEstimatedStart = new Date(task.taskEstimateStart).getTime();
                const taskEstimatedEnd = new Date(task.taskEstimateEnd).getTime();
      
                if (taskEstimatedStart < totalEstimatedStart) {
                  totalEstimatedStart = taskEstimatedStart;
                }
      
                if (taskEstimatedEnd > totalEstimatedEnd) {
                  totalEstimatedEnd = taskEstimatedEnd;
                }
              });
            });
      
            const plannedDuration = totalEstimatedEnd - totalEstimatedStart;
            let plannedHours = plannedDuration / (1000 * 60 * 60 * 24);
            plannedHours = Math.ceil(plannedHours);
            return plannedHours;
          }) : "";
      
          actualTimeData =apiresponse ? apiresponse.map((activity) => {
            let totalActualStart = Infinity;
            let totalActualEnd = 0;
      
            activity.subActivity.forEach((subActivity) => {
              subActivity.taskList.forEach((task) => {
                const taskActualStart = new Date(task.taskActualStart).getTime();
                const taskActualEnd = new Date(task.taskActualEnd).getTime();
      
                if (taskActualStart < totalActualStart) {
                  totalActualStart = taskActualStart;
                }
      
                if (taskActualEnd > totalActualEnd) {
                  totalActualEnd = taskActualEnd;
                }
              });
            });
      
            const actualDuration = totalActualEnd - totalActualStart;
            let actualHours = actualDuration / (1000 * 60 * 60 * 24);
            actualHours = Math.ceil(actualHours);
            return actualHours;
          }) : "";
        }
      }
      else{
        labels = apiresponse ? apiresponse.map((activity) => activity.activityName.length > 10 ? activity.activityName.substring(0, 10) + '...' : activity.activityName) : ""


        plannedTimeData =apiresponse ? apiresponse.map((activity) => {
          let totalEstimatedStart = Infinity;
          let totalEstimatedEnd = 0;
          if (activity.subActivity && activity.subActivity.length > 0) {
            activity.subActivity.forEach((subActivity) => {
              subActivity.taskList.forEach((task) => {
                const taskEstimatedStart = new Date(task.taskEstimateStart).getTime();
                const taskEstimatedEnd = new Date(task.taskEstimateEnd).getTime();
      
                if (taskEstimatedStart < totalEstimatedStart) {
                  totalEstimatedStart = taskEstimatedStart;
                }
      
                if (taskEstimatedEnd > totalEstimatedEnd) {
                  totalEstimatedEnd = taskEstimatedEnd;
                }
              });
            });
          }
    
          const plannedDuration = totalEstimatedEnd - totalEstimatedStart;
          let plannedHours = plannedDuration / (1000 * 60 * 60 * 24);
          plannedHours = Math.ceil(plannedHours);
    
          return plannedHours;
        }) : "";
    
        actualTimeData = apiresponse ? apiresponse.map((activity) => {
          let totalActualStart = Infinity;
          let totalActualEnd = 0;
          if (activity.subActivity && activity.subActivity.length > 0) {
            activity.subActivity.forEach((subActivity) => {
              subActivity.taskList.forEach((task) => {
                const taskActualStart = new Date(task.taskActualStart).getTime();
                const taskActualEnd = new Date(task.taskActualEnd).getTime();
      
                if (taskActualStart < totalActualStart) {
                  totalActualStart = taskActualStart;
                }
      
                if (taskActualEnd > totalActualEnd) {
                  totalActualEnd = taskActualEnd;
                }
              });
            });
          }
    
          const actualDuration = totalActualEnd - totalActualStart;
          let actualHours = actualDuration / (1000 * 60 * 60 * 24);
          actualHours = Math.ceil(actualHours);
          return actualHours;
        })  : "";
      }
    const data = {
        labels,
        datasets: [
          {
            label: 'Planned Time',
            data: selectedActivityPVA != 0 ? selectedSubActivityPVA ? plannedTimeDatasub : plannedTimeData : plannedTimeData,
            backgroundColor: '#345BAE',
          },
          {
            label: 'Actual Time',
            data: selectedActivityPVA != 0 ? selectedSubActivityPVA ? actualTimeDatasub : actualTimeData : actualTimeData,
            backgroundColor: '#EEA47F',
          },
        ],
      };
      // console.log('apiresponse...', apiresponse);
  return <Bar data={data} options={options} />;
}



export function PVAC({PVACresponse , selectedActivityPVAC, selectedLibraryPVAC}) {

  const options = {
      responsive: true,
      scales: {
        y:{
          ticks: {
            beginAtZero: true,
            callback: function (value, index) {
              return '₹ ' +value;
            },
          },
            min: 0,
        },
        x:{
            display: true
        }
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
        },
      },
    };
    
  let labels = PVACresponse.map((activity) => activity.activityName.length > 10 ? activity.activityName.substring(0, 10) + '...' : activity.activityName)
  let plannedCost = []
  let actualCost = []
  if(selectedActivityPVAC != 0){
    if(selectedLibraryPVAC){
      const selectedActivityPVC = PVACresponse.find((activity) => activity.activityName === selectedActivityPVAC);
      if(selectedActivityPVC){
        labels = selectedActivityPVC.subActivity.map((item) => item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name)
        plannedCost = selectedActivityPVC.subActivity.map((item) =>
          item.estimated[selectedLibraryPVAC]
        );

        actualCost = selectedActivityPVC.subActivity.map((item) =>
          item.actual[selectedLibraryPVAC]
        );
      }
    }
    else{
      const selectedActivityPVC = PVACresponse.find((activity) => activity.activityName === selectedActivityPVAC);
      if(selectedActivityPVC){
        labels = selectedActivityPVC.subActivity.map((item) => item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name)
        plannedCost = selectedActivityPVC.subActivity.map((item) =>
        calculateTotalCost(item.estimated)
      );

      actualCost = selectedActivityPVC.subActivity.map((item) =>
        calculateTotalCost(item.actual)
      );
      }
    }
  }else{
    labels = PVACresponse.map((activity) => activity.activityName.length > 10 ? activity.activityName.substring(0, 10) + '...' : activity.activityName)
    plannedCost = PVACresponse.map((activity) =>
      activity.subActivity.reduce((sum, item) => sum + calculateTotalCost(item.estimated), 0)
    );

    actualCost = PVACresponse.map((activity) =>
      activity.subActivity.reduce((sum, item) => sum + calculateTotalCost(item.actual), 0)
    );
  }
  const difference = actualCost.map((cost, index) => cost - plannedCost[index]);
  const totalDifference = difference.reduce((sum, value) => sum + value, 0);
  if(totalDifference <= 0){

  }
  else{

  }
  console.log("totalDifference....", totalDifference);

  const data = {
      labels,
      datasets: [
        {
          label: 'Planned Cost',
          data: plannedCost,
          backgroundColor: '#345BAE',
        },
        {
          label: 'Actual Cost',
          data: actualCost,
          backgroundColor: '#EEA47F',
        },
      ],
    };
return <Bar data={data} options={options} />;
}
function calculateTotalCost(costObject) {
  const keys = Object.keys(costObject);
  let total = 0;

  for (let i = 0; i < keys.length; i++) {
    total += costObject[keys[i]];
  }

  return total;
}

  export function BarChartHorizontal({ costSum, costSumSubAct }) {
    const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
        },
      },
    };
  
    let labels = [];
    let data = [];
  
    // costSum.forEach((item) => {
    //   if (item.mainActivity === costSumSubAct) {
    //     item.forEach((sub) => {
    //       sub.activityName.length > 10 ? labels.push(sub.activityName.substring(0, 10) + '...') : labels.push(sub.activityName);
    //       data.push(sub.activityCount);
    //     });
    //   }
    // });

    // if (labels.length === 0) {
    //   const uniqueLabels = [...new Set(costSum.map(item => item.mainActivity))];
    
    //   labels = uniqueLabels.map(item => item.length > 10 ? item.substring(0, 10) + '...' : item);
    //   data = costSum.map((item) => item.activityCount);
    // }

    
  const matchingItems = costSum.filter((item) => item.mainActivity === costSumSubAct);
  if (matchingItems.length > 0) {
    matchingItems.forEach((matchingItem) => {
      labels.push(matchingItem.activityName);
      data.push(matchingItem.activityCount);
    });
  } else {
    const uniqueMainActivities = [];
    costSum.forEach((item) => {
      if (!uniqueMainActivities.includes(item.mainActivity)) {
        uniqueMainActivities.push(item.mainActivity);
        labels.push(item.mainActivity);
        data.push(item.activityCount);
      } else {
        const index = labels.findIndex((label) => label === item.mainActivity);
        data[index] += item.activityCount;
      }
    });
  }
  
    const chartData = {
      labels,
      datasets: [
        {
          label: 'Cost',
          data,
          borderColor: 'transparent',
          backgroundColor: '#345BAE',
        },
      ],
    };
  
    return <Bar options={options} data={chartData} height={100} width={100} />;
  }
  

  export function BarChartVerticle({ Ppg, subPpg}) {
    const options = {
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          },
        },
        scales: {
          y: {
            max: 100,
            ticks: {
              callback: function (value, index, values) {
                return value + '%';
              },
            },
          },
        },
      };
      
      let labels = [];
      let data = [];
      const matchingItems = Ppg.filter((item) => item.mainActivity === subPpg);
      if (matchingItems.length > 0) {
        matchingItems.forEach((matchingItem) => {
          labels.push(matchingItem.activityName);
          data.push(matchingItem.percentage);
        });
      } 
      else {
        const uniqueMainActivities = [];
        const cumulativePercentage = {};
      
        Ppg.forEach((item) => {
          if (!uniqueMainActivities.includes(item.mainActivity)) {
            uniqueMainActivities.push(item.mainActivity);
            cumulativePercentage[item.mainActivity] = item.percentage;
          } else {
            cumulativePercentage[item.mainActivity] += item.percentage;
          }
        });
      
        uniqueMainActivities.forEach((mainActivity) => {
          const occurrences = Ppg.filter((item) => item.mainActivity === mainActivity).length;
          const calculatedPercentage = cumulativePercentage[mainActivity] / occurrences;
          labels.push(mainActivity);
          data.push(calculatedPercentage);
        });
      }
      // else {
      //   const uniqueMainActivities = [];
      //   Ppg.forEach((item) => {
      //     if (!uniqueMainActivities.includes(item.mainActivity)) {
      //       uniqueMainActivities.push(item.mainActivity);
      //       labels.push(item.mainActivity);
      //       data.push(item.percentage);
      //     } else {
      //       const index = labels.findIndex((label) => label === item.mainActivity);
      //       data[index] += item.percentage;
      //     }
      //   });
      // }
    
      const chartData = {
        labels,
        datasets: [
          {
            label: 'Progress',
            data,
            borderColor: 'transparent',
            backgroundColor: '#345BAE',
          },
        ],
      };

    return <Bar options={options} data={chartData}/>;
  }