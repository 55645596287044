// import React, { Component } from "react";
// import {
//   CardHeader, Typography, Grid, Button,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   TableContainer,
//   Table,
//   TableRow,
//   TableCell,
//   TableHead,
//   TableBody, Tabs, Tab, AppBar
// } from "@material-ui/core";
// import Style from "./style.module.scss";
// import Notifications from "react-notification-system-redux";
// import EditCompletion from "./EditCompletion";
// import Activity from "./Activity";
// import Loading from "../../Components/Loading";
// import { connect } from "react-redux";
// import {
//   getActivities,
//   updateActivity,
// } from "../../Redux/monitoringRedux/monitoringAction";
// import editRoles from "../BarChart/planningEditRoles";
// import TableMonitoring from "./TableMonitoring";
// import { CloudDownload } from "@material-ui/icons";
// import MUIDataTable from "mui-datatables";

// class Monitoring extends Component {
//   constructor(props) {
//     super(props);
//     const role = global.config.secureStorage.getItem("role");
//     const isEditable = editRoles.find(item => item == role) ? true : false;
//     this.state = {
//       activityName: "",
//       estimatedcompletion: "",
//       actualcompletion: "",
//       actualcompletion_by_cost: "",
//       estimatedcompletion_by_cost: "",
//       isEditCompletion: false,
//       loading: true,
//       index: -1,
//       activities: [],
//       isEditable: isEditable,
//       isOpenTable: false,
//       cost: "",
//       tableData: [],
//       tabIndex: null, monitoring: []
//     };
//   }


//   async componentDidMount() {
//     try {
//       this.props.getActivities();

//     } catch (error) { }
//   }


//   componentDidUpdate(pP, pS, sS) {
//     try {

//       if (pS.tabIndex != this.state.tabIndex || this.state.tabIndex == null) {
//         console.log("this.state.tabIndex", this.state.tabIndex) 

//         const cost = [...this.props.cost]
//         const data = [...this.props.monitoring]
//         const subAccCost=[...this.props.subAccCost]
//         const taskCost=[...this.props.taskCost]
//         // const dprSubAccCost=[...this.props.dprSubAccCost]
//         // const dprTaskCost=[...this.props.dprTaskCost]
//         console.log("data is this in monitoring ",this.props.monitoring)
//         console.log("cost is in montoring are ",this.props.cost)
//         let tempData = [];
//         if (this.state.tabIndex === 0 || this.state.tabIndex === 1 || this.state.tabIndex === null) {


//           data.forEach((elem, id) => {
//             let row = [];
//             if (elem !== null) {

//               row.push(

//                 <Typography>{elem.activity}</Typography>,

//                 <Grid>
//                   {elem.subActivities.map((subAcc, id2) => {
//                     return subAcc !== null ?
//                       <Accordion
//                         style={{ backgroundColor: "#f5f5f5" }}
//                         key={id}
//                         defaultExpanded={true}
//                       >
//                         <AccordionSummary>
//                           <Typography>{subAcc.title}</Typography>
//                         </AccordionSummary>

//                         <AccordionDetails>
//                           <TableContainer>
//                             <Table size="small">
//                               <TableHead>
//                                 <TableRow>
//                                   <TableCell>Task Name</TableCell>
//                                   <TableCell>Progress</TableCell>
//                                 </TableRow>
//                               </TableHead>
//                               <TableBody>
//                                 {
//                                   subAcc.tasks.TaskDetails != null ?
//                                     subAcc.tasks.TaskDetails.TaskNames.map((taskInfo, id3) => (


//                                       this.state.tabIndex == 0 || this.state.tabIndex == null ?

//                                         <TableRow key={id2}>
//                                           <TableCell width="30%">
//                                             <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
//                                               {subAcc.tasks.TaskDetails.TaskNames[id3]}
//                                             </Grid>
//                                             <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
//                                               {subAcc.tasks.TaskDetails.Taskdata[id3] >= 100 ? '[Completed]' : '[In Progress]'}
//                                             </Grid>

//                                           </TableCell>

//                                           <TableCell>
//                                             <Grid container>
//                                               <Grid item xs={12} sm={12} lg={4} md={3} style={{ marginTop: '10px' }}>
//                                                 <strong>Date Progress: </strong>
//                                               </Grid>
//                                               <Grid item xs={12} sm={12} lg={8} md={9} style={{ marginTop: '10px' }}>
//                                                 <span style={{ verticalAlign: "sub", display: 'flex' }}>
//                                                   <progress value={subAcc.tasks.TaskDetails.dateProgress[id3]} max="100"></progress>{' '} {subAcc.tasks.TaskDetails.dateProgress[id3]} %
//                                                 </span>
//                                               </Grid>
//                                               <Grid item xs={12} sm={12} lg={4} md={3} style={{ marginTop: '10px' }}>
//                                                 <strong>Percentage Progress: </strong>
//                                               </Grid>
//                                               <Grid item xs={12} sm={12} lg={8} md={9} style={{ marginTop: '10px' }}>
//                                                 <span style={{ verticalAlign: "bottom", display: 'flex' }}>
//                                                   <progress value={subAcc.tasks.TaskDetails.Taskdata[id3]} max="100"></progress>{' '} {subAcc.tasks.TaskDetails.Taskdata[id3]}%
//                                                 </span>
//                                               </Grid>

//                                               <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
//                                                 <strong>{subAcc.tasks.TaskDetails.delay[id3] > 0 ? 'Delay' : 'Before Time'} </strong>
//                                                 <span variant="h6">{Math.abs(subAcc.tasks.TaskDetails.delay[id3])} days</span>
//                                               </Grid>
//                                               <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
//                                                 <strong>Duration: </strong>
//                                                 <span variant="h6">{subAcc.tasks.TaskDetails.duration[id3]} days</span>
//                                               </Grid>
//                                             </Grid>
//                                           </TableCell>




//                                         </TableRow>
//                                         : this.state.tabIndex == 1 ?

//                                           <TableRow key={id2}>
//                                             <TableCell width="30%">
//                                               <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
//                                                 {subAcc.tasks.TaskDetails.TaskNames[id3]}
//                                               </Grid>
//                                               <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
//                                                 {subAcc.tasks.TaskDetails.Taskdata[id3] >= 100 ? '[Completed]' : '[In Progress]'}
//                                               </Grid>

//                                             </TableCell>
//                                             <TableCell>
//                                               <Grid container>



//                                                 <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
//                                                   <strong>Estimate Start Date: </strong>
//                                                   <span variant="h6">{subAcc.tasks.TaskDetails.EstimatedStart[id3]}</span>
//                                                 </Grid>
//                                                 <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
//                                                   <strong>Estimate End Date: </strong>
//                                                   <span variant="h6">{subAcc.tasks.TaskDetails.EstimatedEnd[id3]}</span>
//                                                 </Grid>





//                                                 <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
//                                                   <strong>Actual Start Date: </strong>
//                                                   <span variant="h6">{subAcc.tasks.TaskDetails.ActualStart[id3]}</span>
//                                                 </Grid>
//                                                 <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
//                                                   <strong>Actual End Date: </strong>
//                                                   <span variant="h6">{subAcc.tasks.TaskDetails.ActualEnd[id3]}</span>
//                                                 </Grid>


//                                                 <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
//                                                   <strong>{subAcc.tasks.TaskDetails.delay[id3] > 0 ? 'Delay' : 'Before Time'}: </strong>
//                                                   <span variant="h6">{Math.abs(subAcc.tasks.TaskDetails.delay[id3])}  days</span>
//                                                 </Grid>
//                                                 <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
//                                                   <strong>Duration </strong>
//                                                   <span variant="h6">{subAcc.tasks.TaskDetails.duration[id3]}  days</span>
//                                                 </Grid>


//                                                 <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
//                                                   <strong>Progress </strong>
//                                                   <span variant="h6"> {subAcc.tasks.TaskDetails.Taskdata[id3]}%</span>
//                                                 </Grid>


//                                               </Grid>
//                                             </TableCell>

//                                           </TableRow>
//                                           : ''
//                                     ))
//                                     : ''
//                                 }
//                               </TableBody>
//                             </Table>
//                           </TableContainer>
//                         </AccordionDetails>

//                       </Accordion>
//                       :
//                       ''

//                   })
//                   }
//                 </Grid >



//               );
//               tempData.push(row);
//             }
//           });
//         } else if (this.state.tabIndex === 2) {
//           cost.forEach((elem, id) => {
//             let row = [];
//             if (elem !== null && elem.visibility) {
//               row.push(
//                 <Typography>{elem.title}</Typography>,
//                 <Grid key={id}>
//                   <Grid item xs={12} sm={12} lg={12} md={3} >
//                     <Typography>Estimated Cost: </Typography>

//                     {elem.estimatedcompletion_by_cost &&
//                       elem.estimatedcompletion_by_cost !== "Not available" &&
//                       elem.estimatedcompletion_by_cost !== "Not started yet" ? (
//                       parseFloat(elem.estimatedcompletion_by_cost).toFixed(2) === "NaN" ? (
//                         <span style={{ verticalAlign: "sub" }}>
//                           <progress value="0" max="100"></progress>0.00%
//                         </span>
//                       ) : (
//                         <span style={{ verticalAlign: "sub" }}>
//                           <progresstempData
//                             value={parseFloat(elem.estimatedcompletion_by_cost).toFixed(2)}
//                             max="100"
//                           ></progresstempData>{" "}
//                           ₹{parseFloat(elem.estimatedcompletion_by_cost).toFixed(2)}
//                         </span>
//                       )
//                     ) : (
//                       <span style={{ verticalAlign: "sub" }}>
//                         <progress
//                           value={parseFloat(elem.estimatedcompletion_by_cost).toFixed(2)}
//                           max="100"
//                         ></progress>{" "}
//                         {parseFloat(elem.estimatedcompletion_by_cost).toFixed(2)}%
//                       </span>
//                     )
//                     }


//                   </Grid>
//                   <Grid item xs={12} sm={12} lg={12} md={3} >

//                     <Typography>Actual Cost: </Typography>

//                     {elem.actualcompletion_by_cost &&
//                       elem.actualcompletion_by_cost !== "Not available" &&
//                       elem.actualcompletion_by_cost !== "Not started yet" ? (
//                       parseFloat(elem.actualcompletion_by_cost).toFixed(2) === "NaN" ? (
//                         <span style={{ verticalAlign: "sub" }}>
//                           <progress value="0" max="100"></progress>0.00%
//                         </span>
//                       ) : (
//                         <span style={{ verticalAlign: "sub" }}>
//                           <progress
//                             value={parseFloat(elem.actualcompletion_by_cost).toFixed(2)}
//                             max="100"
//                           ></progress>{" "}
//                           ₹{parseFloat(elem.actualcompletion_by_cost).toFixed(2)}
//                         </span>
//                       )
//                     ) : (
//                       <span style={{ verticalAlign: "sub" }}>
//                         <progress
//                           value={parseFloat(elem.actualcompletion_by_cost).toFixed(2)}
//                           max="100"
//                         ></progress>{" "}
//                         {parseFloat(elem.actualcompletion_by_cost).toFixed(2)}%
//                       </span>
//                     )
//                     }


//                   </Grid>
//                   <Grid style={{marginTop:"10px" , marginBottom:"10px"}}>
//                     {subAccCost && 
//                     subAccCost.map( (subAcc) =>{
//                     let target=  subAcc.activity===  elem.title ?
//                       (
//                         <>
//                         <Typography>
//                         sub activity: {subAcc.subactivity}
//                         </Typography>
//                         <Typography>
//                           Estimated cost
//                         </Typography>
//                         <span style={{ verticalAlign: "sub" }}>
//                         <progress
//                           value={parseFloat(subAcc.exp_cost).toFixed(2)}
//                           max="100"
//                         ></progress>{" "}
//                         ₹{parseFloat(subAcc.exp_cost).toFixed(2)}
//                       </span>

//                       <Typography>
//                           Actual cost
//                         </Typography>
//                         <span style={{ verticalAlign: "sub" }}>
//                         <progress
//                           value={parseFloat(subAcc.ac_cost).toFixed(2)}
//                           max="100"
//                         ></progress>{" "}
//                         ₹{parseFloat(subAcc.ac_cost).toFixed(2)}
//                       </span>

//                       {taskCost.map(task =>{
//                         let taskname=task.task.slice(task.activity.length + task.subactivity.length,task.task.length)
//                         console.log("checker are here ",taskname)

//                         let target1 =task.activity===elem.title && task.subactivity===subAcc.subactivity ?
//                        (
//                           <>
//                           <Typography>
//                         task : {taskname}
//                         </Typography> 
//                         <Typography>
//                           Estimated cost
//                         </Typography>
//                         <span style={{ verticalAlign: "sub" }}>
//                         <progress
//                           value={parseFloat(task.exp_cost).toFixed(2)}
//                           max="100"
//                         ></progress>{" "}
//                         ₹{parseFloat(task.exp_cost).toFixed(2)}
//                       </span>

//                       <Typography>
//                           Actual cost
//                         </Typography>
//                         <span style={{ verticalAlign: "sub" }}>
//                         <progress
//                           value={parseFloat(task.ac_cost).toFixed(2)}
//                           max="100"
//                         ></progress>{" "}
//                         ₹{parseFloat(task.ac_cost).toFixed(2)}
//                       </span>
//                           </>
//                         ):""
//                         return target1
//                       })}
//                         </>
//                       ): ""
//                       return target
//                     })}
//                   </Grid>


//                 </Grid>


//               );
//               tempData.push(row);
//             }
//           });
//         }




//         if (this.props.monitoring.length > 0) {
//           this.setState({
//             tableData: tempData,
//             monitoring: this.props.monitoring
//           });
//           if (this.state.tabIndex == null)
//             this.setState({
//               tabIndex: 0
//             });
//         }

//       }
//     } catch (error) {

//     }

//   }

//   editActivity = (name, index) => {
//     this.setState({
//       editActivityName: name,
//       index: index,
//       activities: this.props.monitoring,
//     });
//     const Activity = this.props.monitoring.find(Activity => {
//       return Activity.title === name;
//     });
//     this.setState({
//       isEditCompletion: !this.state.isEditCompletion,
//       activityName: Activity.title,
//       estimatedcompletion: Activity.estimatedcompletion,
//       actualcompletion: Activity.actualcompletion,
//       actualcompletion_by_cost: Activity.actualcompletion_by_cost,
//       estimatedcompletion_by_cost: Activity.estimatedcompletion_by_cost,
//     });
//   };
//   handleClick = () => {
//     this.setState({ isEditCompletion: !this.state.isEditCompletion });
//   };

//   handleSubmit = async index => {
//     if (this.state.activities.length == 0) {
//       await this.setState({
//         activities: this.props.monitoring,
//       });
//     }

//     var data = this.state.activities;
//     var prev = data[index];
//     prev = {
//       ...prev,
//       actualcompletion: this.state.actualcompletion,
//     };
//     data[index] = prev;
//     this.setState({
//       activities: data,
//       isEditCompletion: false,
//     });
//     this.props.updateActivity(data);
//   };

//   toggleOpenTable = () => {
//     this.setState({ isOpenTable: !this.state.isOpenTable });
//   };

//   toggleCloseTable = () => {
//     this.setState({ isOpenTable: false });
//   };
//   //
//   handelTabs = (e, val) => {
//     console.log(val)
//     this.setState({
//       tabIndex: val
//     });
//   }

//   handleChange = event => {
//     if (this.state.activities.length == 0) {
//       this.setState({
//         activities: this.props.monitoring,
//       });
//     }
//     this.setState({
//       [event.target.name]: event.target.value,
//     });
//   };
//   handleRemark = async (remark, index) => {
//     if (this.state.activities.length == 0) {
//       await this.setState({
//         activities: this.props.monitoring,
//       });
//     }



//     const newActivities = this.state.activities.map((activity, i) => {
//       if (i === index) {
//         const change = { ...activity, remark: remark };
//         return change;
//       }
//       return activity;
//     });
//     this.props.updateActivity(newActivities);

//     //
//     this.setState({
//       activities: newActivities,
//     });
//   };

//   render() {

//     const columns = [
//       {
//         name: "Activity",
//         label: "Activity",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "SubActivity",
//         label: "SubActivity",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//     ];
//     const { isEditCompletion } = this.state;

//     if (isEditCompletion) {
//       return (
//         <EditCompletion
//           activityName={this.state.activityName}
//           estimatedcompletion={this.state.estimatedcompletion}
//           actualcompletion={this.state.actualcompletion}
//           actualcompletion_by_cost={this.state.actualcompletion_by_cost}
//           estimatedcompletion_by_cost={this.state.estimatedcompletion_by_cost}
//           handleClick={this.handleClick}
//           handleChange={this.handleChange}
//           index={this.state.index}
//           handleSubmit={this.handleSubmit}
//         />
//       );
//     }
//     const { isOpenTable } = this.state;
//     if (isOpenTable) {
//       return (
//         <TableMonitoring
//           title="Monitoring"
//           subtitle="Monitoring table"
//           toggleCloseTable={this.toggleCloseTable}
//           monitoring={this.props.monitoring}
//         />
//       );
//     }

//     if (!isOpenTable) {
//       return (
//         <div>
//           {this.props.notifications && (
//             <Notifications notifications={this.props.notifications} />
//           )}
//           {this.props.loading ? (
//             <Loading loading={true} />
//           ) : (
//             <>


//               {this.state.tableData ?
//                 <Grid container justify="center">
//                   <Grid item lg={8}>
//                     <div style={{ margin: "0 20px" }} className="mt-4 mb-4">
//                       <AppBar position="static">
//                         <Tabs value={this.state.tabIndex} onChange={this.handelTabs} TabIndicatorProps={{ style: { height: "4px" } }}>
//                           <Tab label="Progress"></Tab>
//                           <Tab label="Detailed Info"></Tab>
//                           <Tab label="Cost" ></Tab>
//                         </Tabs>
//                       </AppBar>

//                       <MUIDataTable
//                         title={`Monitoring Report `}
//                         data={this.state.tableData}
//                         columns={columns}
//                         options={{
//                           selectableRows: "single",
//                           responsive: "verticle",
//                           pagination: false,
//                           download: false,
//                           viewColumns: false,
//                           filter: false,
//                           print: true,
//                           selectableRows: false,
                          
//                           // onRowsDelete: row_data => {
//                           //   let tempData = data;
//                           //   const ids = row_data.data.map(elem => {
//                           //     tempData.splice(elem.index, 1);
//                           //     return props.dprs[elem.index]._id;
//                           //   });
//                           //   setData(tempData);
//                           //   props.handleDelete(ids);
//                           //   ids.forEach(id => {
//                           //     axios
//                           //       .post(`${global.config.backendURL}/dpr/deleteDpr`, {
//                           //         id: id,
//                           //       })
//                           //       .catch(err => {
//                           //         console.log(err);
//                           //         setOpenDeleteFailure(true);
//                           //       });
//                           //   });
//                           // },
//                         }}
//                       />
//                     </div>
//                   </Grid>
//                 </Grid> : ''}


//             </>
//           )}
//         </div>
//       );
//     }
//   }
// }
// const mapStateToProps = state => {
//   //
//   return {
//     loading: state.monitoringReducer.loading,
//     cost: state.monitoringReducer.cost,
//     monitoring: state.monitoringReducer.monitoring,
//     subAccCost:state.monitoringReducer.subAccCost,
//     taskCost:state.monitoringReducer.taskCost,
//     notifications: state.notifications,
//   };
// };

// export default connect(mapStateToProps, { getActivities, updateActivity })(
//   Monitoring
// );

import React, { Component } from "react";
import {
  CardHeader, Typography, Grid, Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody, Tabs, Tab, AppBar
} from "@material-ui/core";
import Style from "./style.module.scss";
import Notifications from "react-notification-system-redux";
import EditCompletion from "./EditCompletion";
import Activity from "./Activity";
import Loading from "../../Components/Loading";
import { connect } from "react-redux";
import {
  getActivities,
  updateActivity,
} from "../../Redux/monitoringRedux/monitoringAction";
import editRoles from "../BarChart/planningEditRoles";
import TableMonitoring from "./TableMonitoring";
import { CloudDownload } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";

class Monitoring extends Component {
  constructor(props) {
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const isEditable = editRoles.find(item => item == role) ? true : false;
    this.state = {
      activityName: "",
      estimatedcompletion: "",
      actualcompletion: "",
      actualcompletion_by_cost: "",
      estimatedcompletion_by_cost: "",
      isEditCompletion: false,
      loading: true,
      index: -1,
      activities: [],
      isEditable: isEditable,
      isOpenTable: false,
      cost: "",
      tableData: [],
      tabIndex: null, monitoring: []
    };
  }


  async componentDidMount() {
    try {
      this.props.getActivities();

    } catch (error) { }
  }


  componentDidUpdate(pP, pS, sS) {
    try {

      if (pS.tabIndex != this.state.tabIndex || this.state.tabIndex == null) {
        { console.log(this.state.tabIndex) }

        const cost = [...this.props.cost]
        const data = [...this.props.monitoring]
        let tempData = [];
        if (this.state.tabIndex === 0 || this.state.tabIndex === 1 || this.state.tabIndex === null) {


          data.forEach((elem, id) => {
            let row = [];
            if (elem !== null) {

              row.push(

                <Typography>{elem.activity}</Typography>,

                <Grid>
                  {elem.subActivities.map((subAcc, id2) => {
                    return subAcc !== null ?
                      <Accordion
                        style={{ backgroundColor: "#f5f5f5" }}
                        key={id}
                        defaultExpanded={true}
                      >
                        <AccordionSummary>
                          <Typography>{subAcc.title}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Task Name</TableCell>
                                  <TableCell>Progress</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  subAcc.tasks.TaskDetails != null ?
                                    subAcc.tasks.TaskDetails.TaskNames.map((taskInfo, id3) => (


                                      this.state.tabIndex == 0 || this.state.tabIndex == null ?

                                        <TableRow key={id2}>
                                          <TableCell width="30%">
                                            <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
                                              {subAcc.tasks.TaskDetails.TaskNames[id3]}
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
                                              {subAcc.tasks.TaskDetails.Taskdata[id3] >= 100 ? '[Completed]' : '[In Progress]'}
                                            </Grid>

                                          </TableCell>

                                          <TableCell>
                                            <Grid container>
                                              <Grid item xs={12} sm={12} lg={4} md={3} style={{ marginTop: '10px' }}>
                                                <strong>Date Progress: </strong>
                                              </Grid>
                                              <Grid item xs={12} sm={12} lg={8} md={9} style={{ marginTop: '10px' }}>
                                                <span style={{ verticalAlign: "sub", display: 'flex' }}>
                                                  <progress value={subAcc.tasks.TaskDetails.dateProgress[id3]} max="100"></progress>{' '} {subAcc.tasks.TaskDetails.dateProgress[id3]} %
                                                </span>
                                              </Grid>
                                              <Grid item xs={12} sm={12} lg={4} md={3} style={{ marginTop: '10px' }}>
                                                <strong>Percentage Progress: </strong>
                                              </Grid>
                                              <Grid item xs={12} sm={12} lg={8} md={9} style={{ marginTop: '10px' }}>
                                                <span style={{ verticalAlign: "bottom", display: 'flex' }}>
                                                  <progress value={subAcc.tasks.TaskDetails.Taskdata[id3]} max="100"></progress>{' '} {subAcc.tasks.TaskDetails.Taskdata[id3]}%
                                                </span>
                                              </Grid>

                                              <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
                                                <strong>{subAcc.tasks.TaskDetails.delay[id3] > 0 ? 'Delay' : 'Before Time'} </strong>
                                                <span variant="h6">{Math.abs(subAcc.tasks.TaskDetails.delay[id3])} days</span>
                                              </Grid>
                                              <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
                                                <strong>Duration: </strong>
                                                <span variant="h6">{subAcc.tasks.TaskDetails.duration[id3]} days</span>
                                              </Grid>
                                            </Grid>
                                          </TableCell>




                                        </TableRow>
                                        : this.state.tabIndex == 1 ?

                                          <TableRow key={id2}>
                                            <TableCell width="30%">
                                              <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
                                                {subAcc.tasks.TaskDetails.TaskNames[id3]}
                                              </Grid>
                                              <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
                                                {subAcc.tasks.TaskDetails.Taskdata[id3] >= 100 ? '[Completed]' : '[In Progress]'}
                                              </Grid>

                                            </TableCell>
                                            <TableCell>
                                              <Grid container>



                                                <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
                                                  <strong>Estimate Start Date: </strong>
                                                  <span variant="h6">{subAcc.tasks.TaskDetails.EstimatedStart[id3]}</span>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6} md={3} style={{ marginTop: '10px' }}>
                                                  <strong>Estimate End Date: </strong>
                                                  <span variant="h6">{subAcc.tasks.TaskDetails.EstimatedEnd[id3]}</span>
                                                </Grid>





                                                <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
                                                  <strong>Actual Start Date: </strong>
                                                  <span variant="h6">{subAcc.tasks.TaskDetails.ActualStart[id3]}</span>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
                                                  <strong>Actual End Date: </strong>
                                                  <span variant="h6">{subAcc.tasks.TaskDetails.ActualEnd[id3]}</span>
                                                </Grid>


                                                <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
                                                  <strong>{subAcc.tasks.TaskDetails.delay[id3] > 0 ? 'Delay' : 'Before Time'}: </strong>
                                                  <span variant="h6">{Math.abs(subAcc.tasks.TaskDetails.delay[id3])}  days</span>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
                                                  <strong>Duration </strong>
                                                  <span variant="h6">{subAcc.tasks.TaskDetails.duration[id3]}  days</span>
                                                </Grid>


                                                <Grid item xs={12} sm={12} lg={6} md={6} style={{ marginTop: '10px' }}>
                                                  <strong>Progress </strong>
                                                  <span variant="h6"> {subAcc.tasks.TaskDetails.Taskdata[id3]}%</span>
                                                </Grid>


                                              </Grid>
                                            </TableCell>

                                          </TableRow>
                                          : ''
                                    ))
                                    : ''
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>

                      </Accordion>
                      :
                      ''

                  })
                  }
                </Grid >



              );
              tempData.push(row);
            }
          });
        } else if (this.state.tabIndex === 2) {
          cost.forEach((elem, id) => {
            let row = [];
            if (elem !== null && elem.visibility) {
              row.push(
                <Typography>{elem.title}</Typography>,
                <Grid key={id}>
                  <Grid item xs={12} sm={12} lg={12} md={3} >
                    <Typography>Estimated Cost: </Typography>

                    {elem.estimatedcompletion_by_cost &&
                      elem.estimatedcompletion_by_cost !== "Not available" &&
                      elem.estimatedcompletion_by_cost !== "Not started yet" ? (
                      parseFloat(elem.estimatedcompletion_by_cost).toFixed(2) === "NaN" ? (
                        <span style={{ verticalAlign: "sub" }}>
                          <progress value="0" max="100"></progress>0.00%
                        </span>
                      ) : (
                        <span style={{ verticalAlign: "sub" }}>
                          <progress
                            value={parseFloat(elem.estimatedcompletion_by_cost).toFixed(2)}
                            max="100"
                          ></progress>{" "}
                          ₹{parseFloat(elem.estimatedcompletion_by_cost).toFixed(2)}
                        </span>
                      )
                    ) : (
                      <span style={{ verticalAlign: "sub" }}>
                        <progress
                          value={parseFloat(elem.estimatedcompletion_by_cost).toFixed(2)}
                          max="100"
                        ></progress>{" "}
                        {parseFloat(elem.estimatedcompletion_by_cost).toFixed(2)}%
                      </span>
                    )
                    }


                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} md={3} >

                    <Typography>Actual Cost: </Typography>

                    {elem.actualcompletion_by_cost &&
                      elem.actualcompletion_by_cost !== "Not available" &&
                      elem.actualcompletion_by_cost !== "Not started yet" ? (
                      parseFloat(elem.actualcompletion_by_cost).toFixed(2) === "NaN" ? (
                        <span style={{ verticalAlign: "sub" }}>
                          <progress value="0" max="100"></progress>0.00%
                        </span>
                      ) : (
                        <span style={{ verticalAlign: "sub" }}>
                          <progress
                            value={parseFloat(elem.actualcompletion_by_cost).toFixed(2)}
                            max="100"
                          ></progress>{" "}
                          ₹{parseFloat(elem.actualcompletion_by_cost).toFixed(2)}
                        </span>
                      )
                    ) : (
                      <span style={{ verticalAlign: "sub" }}>
                        <progress
                          value={parseFloat(elem.actualcompletion_by_cost).toFixed(2)}
                          max="100"
                        ></progress>{" "}
                        {parseFloat(elem.actualcompletion_by_cost).toFixed(2)}%
                      </span>
                    )
                    }


                  </Grid>



                </Grid>


              );
              tempData.push(row);
            }
          });
        }




        if (this.props.monitoring.length > 0) {
          this.setState({
            tableData: tempData,
            monitoring: this.props.monitoring
          });
          if (this.state.tabIndex == null)
            this.setState({
              tabIndex: 0
            });
        }

      }
    } catch (error) {

    }

  }

  editActivity = (name, index) => {
    this.setState({
      editActivityName: name,
      index: index,
      activities: this.props.monitoring,
    });
    const Activity = this.props.monitoring.find(Activity => {
      return Activity.title === name;
    });
    this.setState({
      isEditCompletion: !this.state.isEditCompletion,
      activityName: Activity.title,
      estimatedcompletion: Activity.estimatedcompletion,
      actualcompletion: Activity.actualcompletion,
      actualcompletion_by_cost: Activity.actualcompletion_by_cost,
      estimatedcompletion_by_cost: Activity.estimatedcompletion_by_cost,
    });
  };
  handleClick = () => {
    this.setState({ isEditCompletion: !this.state.isEditCompletion });
  };

  handleSubmit = async index => {
    if (this.state.activities.length == 0) {
      await this.setState({
        activities: this.props.monitoring,
      });
    }

    var data = this.state.activities;
    var prev = data[index];
    prev = {
      ...prev,
      actualcompletion: this.state.actualcompletion,
    };
    data[index] = prev;
    this.setState({
      activities: data,
      isEditCompletion: false,
    });
    this.props.updateActivity(data);
  };

  toggleOpenTable = () => {
    this.setState({ isOpenTable: !this.state.isOpenTable });
  };

  toggleCloseTable = () => {
    this.setState({ isOpenTable: false });
  };
  //
  handelTabs = (e, val) => {
    console.log(val)
    this.setState({
      tabIndex: val
    });
  }

  handleChange = event => {
    if (this.state.activities.length == 0) {
      this.setState({
        activities: this.props.monitoring,
      });
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleRemark = async (remark, index) => {
    if (this.state.activities.length == 0) {
      await this.setState({
        activities: this.props.monitoring,
      });
    }



    const newActivities = this.state.activities.map((activity, i) => {
      if (i === index) {
        const change = { ...activity, remark: remark };
        return change;
      }
      return activity;
    });
    this.props.updateActivity(newActivities);

    //
    this.setState({
      activities: newActivities,
    });
  };

  render() {

    const columns = [
      {
        name: "Activity",
        label: "Activity",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "SubActivity",
        label: "SubActivity",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];
    const { isEditCompletion } = this.state;

    if (isEditCompletion) {
      return (
        <EditCompletion
          activityName={this.state.activityName}
          estimatedcompletion={this.state.estimatedcompletion}
          actualcompletion={this.state.actualcompletion}
          actualcompletion_by_cost={this.state.actualcompletion_by_cost}
          estimatedcompletion_by_cost={this.state.estimatedcompletion_by_cost}
          handleClick={this.handleClick}
          handleChange={this.handleChange}
          index={this.state.index}
          handleSubmit={this.handleSubmit}
        />
      );
    }
    const { isOpenTable } = this.state;
    if (isOpenTable) {
      return (
        <TableMonitoring
          title="Monitoring"
          subtitle="Monitoring table"
          toggleCloseTable={this.toggleCloseTable}
          monitoring={this.props.monitoring}
        />
      );
    }

    if (!isOpenTable) {
      return (
        <div>
          {this.props.notifications && (
            <Notifications notifications={this.props.notifications} />
          )}
          {this.props.loading ? (
            <Loading loading={true} />
          ) : (
            <>


              {this.state.tableData ?
                <Grid container justify="center">
                  <Grid item lg={6}>
                    <div style={{ margin: "0 20px" }} className="mt-4 mb-4">
                      <AppBar position="static">
                        <Tabs value={this.state.tabIndex} onChange={this.handelTabs} TabIndicatorProps={{ style: { height: "4px" } }}>
                          <Tab label="Progress"></Tab>
                          <Tab label="Detailed Info"></Tab>
                          <Tab label="Cost" ></Tab>
                        </Tabs>
                      </AppBar>

                      <MUIDataTable
                        title={`Monitoring Report `}
                        data={this.state.tableData}
                        columns={columns}
                        options={{
                          selectableRows: "single",
                          responsive: "simple",
                          pagination: false,
                          download: false,
                          viewColumns: false,
                          filter: false,
                          print: true,
                          selectableRows: false
                          // onRowsDelete: row_data => {
                          //   let tempData = data;
                          //   const ids = row_data.data.map(elem => {
                          //     tempData.splice(elem.index, 1);
                          //     return props.dprs[elem.index]._id;
                          //   });
                          //   setData(tempData);
                          //   props.handleDelete(ids);
                          //   ids.forEach(id => {
                          //     axios
                          //       .post(`${global.config.backendURL}/dpr/deleteDpr`, {
                          //         id: id,
                          //       })
                          //       .catch(err => {
                          //         console.log(err);
                          //         setOpenDeleteFailure(true);
                          //       });
                          //   });
                          // },
                        }}
                      />
                    </div>
                  </Grid>
                </Grid> : ''}


            </>
          )}
        </div>
      );
    }
  }
}
const mapStateToProps = state => {
  //
  return {
    loading: state.monitoringReducer.loading,
    cost: state.monitoringReducer.cost,
    monitoring: state.monitoringReducer.monitoring,
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps, { getActivities, updateActivity })(
  Monitoring
);
