import React, { useEffect, useState } from 'react';
import { ApiGetMethod } from '../Components/apiHelper/helper';
import { GroupChart } from './overview/GroupBarChart';
const TestPage = props => {
    const [testdata, setTestData] = React.useState([]);
    const formData = new FormData();
    useEffect(() => {
      var pId = global.config.secureStorage.getItem("projectId");
      let data = formData;
      let urlPVA = `${global.config.backendURL}/dashboard/GetPvaTime/` + pId;
      ApiGetMethod(urlPVA, data)
        .then((response) => {
          console.log('testurlPVA...', response.subProjects[0].activities);
          setTestData(response.subProjects[0].activities);
          //   console.log('urlPVAty...', testdata);
        });
    }, []);

    return (
        <>
            {testdata.map((item, index) => {
                return (
                    <div key={item.activityName}>
                        <h1>{index} : {item.activityName}</h1>
                        {item.subActivity.map((sub) => (
                            <div key={sub.name}>
                                <h2>{sub.name}</h2>
                                <p>Task List:
                                    {sub.taskList.map((task, taskIndex) => (
                                        <span key={taskIndex}> {task.name},</span>
                                    ))}
                                </p>
                            </div>
                        ))}
                    </div>
                );
            })}
            <GroupChart apiresponse={testdata} />
        </>
    )
}
export default TestPage