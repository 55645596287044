import React, { Component } from "react";
import {
  Grid,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Slide,
} from "@material-ui/core";
import { connect } from "react-redux";
import Loading from "../../Components/Loading";
import { Alert } from "@material-ui/lab";
import { ArrowBack, FilterList, CloudDownload } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Save } from "@material-ui/icons";
import Style from "./style.module.scss";
import {
  getWbs,
  clearReport,
  getDPRWbs,
  getMaterialsWbs,
  getLaboursWbs,
  getcontractorRaWbs,
  getSupplierWbs,
  getStoreWbs
} from "../../Redux/wbsRedux/wbsAction";
import ActivityReport from "./ActivityReport";
import Filter from "./FilterBox";

import DPRReport from "./DPR Report/DPRReport";
import FilterDPRReport from "./DPR Report/FilterDPRReport";
import MaterialsReport from "./Materials Report/MaterialsReport";
import FilterMaterialsReport from "./Materials Report/FilterMaterialsReport";
import LaboursReport from "./Labours Report/LaboursReport";
import FilterLaboursReport from "./Labours Report/FilterLaboursReport";

import ContractorRaReport from "./Contractor RA Report/ContractorRaReport";
import FilterContractorRa from "./Contractor RA Report/FilterContractorRa";

import SuppilerReport from "./Suppiler Report/SupplierReport";
import FilterSuppilerReport from "./Suppiler Report/FilterSupplierReport";

import StoreReport from "./Store Report/StoreReport";
import FilterStoreReport from "./Store Report/FilterStoreReport";


import downloadPDF from "../../Components/pdf";
class Wbs extends Component {
  constructor(props) {
    // console.log(iterList);
    super(props);
    this.state = {
      modalOpen: false,
      loading: true,
      activeReport: 0,
      showFilter: false,
      showScreen: false,
      startDate: "",
      endDate: "",
      DprReportFormat: "",
      ReportFormat: "",
      openDownloadStarted: false,
      // show:true
    };
  }

  TransitionLeft = props => {
    return <Slide {...props} direction="left" />;
  };
  componentDidMount() {
    try {
    } catch (error) {}
  }
  Reports = [
    // "Activity Report",
    "Project Progress Report",
    "DPR Report",
    // "Material wise supplier report",
    "Material Consumption Report",
    "Supplier Wise Material  Report",
    "Labour Wise Supplier Report",
    // "Supplier wise Labour report",
    "Labour Report",
    // "Contractor wise items report",
    "Contractor RA",
    "Item Wise Contractors Report",
    "Crusher Sale Party Name Wise Material Report",
    "Crusher Sale Material Wise Party Name Report",
    "Store Report",
  ];

  clearReportAll=()=>{
    this.props.clearReport();
    this.setState({
      startDate: "",
      endDate: "",
      DprReportFormat: "",
      ReportFormat: "",
    });
  }

  handleShowReport = i => {
    console.log(i);
    this.setState({
      activeReport: i ? i : 0,
      showScreen: !this.state.showScreen,
    });
  };
  handleFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  };

  handleInputChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  getReport = activityName => {
    console.log("activityname", activityName);
    this.props.getWbs(activityName, this.state.startDate, this.state.endDate);
    this.setState({
      startDate: "",
      endDate: "",
    });
    this.handleFilter();
  };
  convert = str => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  getDPRReport = activityName => {
    this.props.getDPRWbs(this.state.startDate, this.state.endDate);
    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      DprReportFormat: activityName,
    });
    this.handleFilter();
  };
  getMaterialsReport = () => {
    let ReportFormat = "";
    if (this.state.activeReport == 2) {
      ReportFormat = "Material Wise";
    }
    if (this.state.activeReport == 3) {
      ReportFormat = "Suppiler Wise";
    }
    this.props.getMaterialsWbs(
      this.state.startDate,
      this.state.endDate,
      ReportFormat
    );

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      ReportFormat: ReportFormat,
    });
    this.handleFilter();
  };
  getLaboursReport = () => {
    let ReportFormat = "";
    if (this.state.activeReport == 4) {
      ReportFormat = "Labours Wise";
    }
    if (this.state.activeReport == 5) {
      ReportFormat = "Suppiler Wise";
    }
    this.props.getLaboursWbs(
      this.state.startDate,
      this.state.endDate,
      ReportFormat
    );

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      ReportFormat: ReportFormat,
    });
    this.handleFilter();
  };
  getcontractorRaReport = () => {
    let ReportFormat = "";
    if (this.state.activeReport == 6) {
      ReportFormat = "Contractor Wise";
    }
    if (this.state.activeReport == 7) {
      ReportFormat = "Item Wise";
    }

    this.props.getcontractorRaWbs(
      this.state.startDate,
      this.state.endDate,
      ReportFormat
    );

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      ReportFormat: ReportFormat,
    });
    this.handleFilter();
  };
  getsuppilerReport = () => {
    let ReportFormat = "";
    if (this.state.activeReport == 8) {
      ReportFormat = "Supplier Wise";
    }
    if (this.state.activeReport == 9) {
      ReportFormat = "Material Wise";
    }

    console.log('activeReport',ReportFormat)
    this.props.getSupplierWbs(
      this.state.startDate,
      this.state.endDate,
      ReportFormat
    );

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      ReportFormat: ReportFormat,
    });
    this.handleFilter();
  };
  getStoreReport = () => {
        
    this.props.getStoreWbs(this.state.startDate,
      this.state.endDate,)

    this.setState({
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
    });
    this.handleFilter();
  };
  componentWillUnmount() {
    this.props.clearReport();
  }

  shownotifications() {
    return (
      <>
        {this.state.openDownloadStarted && (
          <Snackbar
            open={this.state.openDownloadStarted}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={this.TransitionLeft}
            onClose={() =>
              this.setState({
                openDownloadStarted: false,
              })
            }
          >
            <Alert
              severity="success"
              onClose={() =>
                this.setState({
                  openDownloadStarted: false,
                })
              }
              style={{ borderTop: "5px solid green" }}
            >
              <div style={{ color: "green" }}>
                <strong>Download Started</strong>
              </div>
              Check Your Downloads Location!
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }

  getStepContent() {
    switch (this.state.activeReport) {
      case 0:
        return (
          <div>
            {this.getReportContent()}
            <Filter
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getReport={this.getReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
        );
        case 1:
          return(
            <div>
            {this.getReportContent()}
            <FilterDPRReport
              showFilter={this.state.showFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              getDPRReport={this.getDPRReport}
              handleFilter={this.handleFilter}
              handleInputChange={this.handleInputChange}
            />
          </div>
          )
          case 2:
            return(
              <div>
              {this.getReportContent()}
              <FilterMaterialsReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getMaterialsReport={this.getMaterialsReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )
          case 3:
            return(
              <div>
              {this.getReportContent()}
              <FilterMaterialsReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getMaterialsReport={this.getMaterialsReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )
          case 4:
            return(
              <div>
              {this.getReportContent()}
              <FilterLaboursReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getLaboursReport={this.getLaboursReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )
          case 5:
            return(
              <div>
              {this.getReportContent()}
              <FilterLaboursReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getLaboursReport={this.getLaboursReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )
          case 6:
            return(
              <div>
              {this.getReportContent()}
              <FilterContractorRa
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getcontractorRaReport={this.getcontractorRaReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )
          case 7:
            return(
              <div>
              {this.getReportContent()}
              <FilterContractorRa
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getcontractorRaReport={this.getcontractorRaReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )
          case 8:
            return(
              <div>
              {this.getReportContent()}
              <FilterSuppilerReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getsuppilerReport={this.getsuppilerReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )
          case 9:
            return(
              <div>
              {this.getReportContent()}
              <FilterSuppilerReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getsuppilerReport={this.getsuppilerReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )
          case 10:
            return(
              <div>
              {this.getReportContent()}
              <FilterStoreReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                getStoreReport={this.getStoreReport}
                handleFilter={this.handleFilter}
                handleInputChange={this.handleInputChange}
              />
            </div>
            )

      default:
        return "Unknown Report";
    }
  }

  getReportContent() {
    switch (this.state.activeReport) {
      case 0:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                  onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    downloadPDF("MIS Activity", "l");
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              // <ActivityReport
              //   showFilter={this.state.showFilter}
              //   startDate={this.state.startDate}
              //   endDate={this.state.endDate}
              //   loading={this.props.loading}
              //   newWbs={this.props.newWbs}
              //   handleInputChange={this.handleInputChange}
              // />
              <ActivityReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                loading={this.props.loading}
                newWbs={this.props.newWbs}
                handleInputChange={this.handleInputChange}
              />
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 1:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                 onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    downloadPDF("MIS DPR", "l");
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <DPRReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                loading={this.props.loading}
                newWbs={this.props.newWbs}
                DprReportFormat={this.state.DprReportFormat}
                handleInputChange={this.handleInputChange}
              />
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 2:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
               onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    downloadPDF("MIS Material", "p");
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <MaterialsReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                loading={this.props.loading}
                newWbs={this.props.newWbs}
                handleInputChange={this.handleInputChange}
                ReportFormat={this.state.ReportFormat}
              />
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 3:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                   onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    downloadPDF("MIS Material", "p");
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <MaterialsReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                loading={this.props.loading}
                newWbs={this.props.newWbs}
                handleInputChange={this.handleInputChange}
                ReportFormat={this.state.ReportFormat}
              />
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 4:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                   onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    downloadPDF("MIS Labours", "p");
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <LaboursReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                loading={this.props.loading}
                newWbs={this.props.newWbs}
                handleInputChange={this.handleInputChange}
                ReportFormat={this.state.ReportFormat}
              />
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
      case 5:
        return (
          <div className="container">
            {this.shownotifications()}
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <Button
                   onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                  className={Style.generatePDF}
                  startIcon={<ArrowBack />}
                  color="secondary"
                />
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={this.handleFilter}
                  className={Style.generatePDF}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>
              </Grid>
              <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                <Button
                  xs={12}
                  onClick={() => {
                    downloadPDF("MIS Labours", "p");
                    this.setState({
                      openDownloadStarted: true,
                    });
                  }}
                  className={Style.generatePDF}
                  endIcon={<CloudDownload />}
                >
                  Export Report
                </Button>
              </Grid>
            </Grid>
            {this.props.loading ? (
              <Loading loading={this.props.loading} />
            ) : !this.props.loading && this.props.newWbs.length ? (
              <LaboursReport
                showFilter={this.state.showFilter}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                loading={this.props.loading}
                newWbs={this.props.newWbs}
                handleInputChange={this.handleInputChange}
                ReportFormat={this.state.ReportFormat}
              />
            ) : (
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginTop: "2em", textAlign: "center" }}
              >
                Select Filter To Generate Report!!
              </Typography>
            )}
          </div>
        );
        case 6:
          return (
            <div className="container">
              {this.shownotifications()}
              <Grid container justify="space-around">
                <Grid item xs={12}>
                  <Button
                     onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                    className={Style.generatePDF}
                    startIcon={<ArrowBack />}
                    color="secondary"
                  />
                </Grid>
                <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Button
                    xs={12}
                    onClick={this.handleFilter}
                    className={Style.generatePDF}
                    startIcon={<FilterList />}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Button
                    xs={12}
                    onClick={() => {
                      downloadPDF("Contractor Ra Report", "p");
                      this.setState({
                        openDownloadStarted: true,
                      });
                    }}
                    className={Style.generatePDF}
                    endIcon={<CloudDownload />}
                  >
                    Export Report
                  </Button>
                </Grid>
              </Grid>
              {this.props.loading ? (
                <Loading loading={this.props.loading} />
              ) : !this.props.loading && this.props.newWbs.length ? (
                <ContractorRaReport
                  showFilter={this.state.showFilter}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  loading={this.props.loading}
                  newWbs={this.props.newWbs}
                  handleInputChange={this.handleInputChange}
                  ReportFormat={this.state.ReportFormat}
                />
              ) : (
                <Typography
                  variant="h4"
                  color="textSecondary"
                  style={{ marginTop: "2em", textAlign: "center" }}
                >
                  Select Filter To Generate Report!!
                </Typography>
              )}
            </div>
          );
        case 7:
          return (
            <div className="container">
              {this.shownotifications()}
              <Grid container justify="space-around">
                <Grid item xs={12}>
                  <Button
                     onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                    className={Style.generatePDF}
                    startIcon={<ArrowBack />}
                    color="secondary"
                  />
                </Grid>
                <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Button
                    xs={12}
                    onClick={this.handleFilter}
                    className={Style.generatePDF}
                    startIcon={<FilterList />}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Button
                    xs={12}
                    onClick={() => {
                      downloadPDF("Contractor Ra Report", "p");
                      this.setState({
                        openDownloadStarted: true,
                      });
                    }}
                    className={Style.generatePDF}
                    endIcon={<CloudDownload />}
                  >
                    Export Report
                  </Button>
                </Grid>
              </Grid>
              {this.props.loading ? (
                <Loading loading={this.props.loading} />
              ) : !this.props.loading && this.props.newWbs.length ? (
                <ContractorRaReport
                  showFilter={this.state.showFilter}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  loading={this.props.loading}
                  newWbs={this.props.newWbs}
                  handleInputChange={this.handleInputChange}
                  ReportFormat={this.state.ReportFormat}
                />
              ) : (
                <Typography
                  variant="h4"
                  color="textSecondary"
                  style={{ marginTop: "2em", textAlign: "center" }}
                >
                  Select Filter To Generate Report!!
                </Typography>
              )}
            </div>
          );
          case 8:
            return (
              <div className="container">
                {this.shownotifications()}
                <Grid container justify="space-around">
                  <Grid item xs={12}>
                    <Button
                       onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                      className={Style.generatePDF}
                      startIcon={<ArrowBack />}
                      color="secondary"
                    />
                  </Grid>
                  <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                    <Button
                      xs={12}
                      onClick={this.handleFilter}
                      className={Style.generatePDF}
                      startIcon={<FilterList />}
                    >
                      Filter
                    </Button>
                  </Grid>
                  <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                    <Button
                      xs={12}
                      onClick={() => {
                        downloadPDF("Store Report", "p");
                        this.setState({
                          openDownloadStarted: true,
                        });
                      }}
                      className={Style.generatePDF}
                      endIcon={<CloudDownload />}
                    >
                      Export Report
                    </Button>
                  </Grid>
                </Grid>
                {this.props.loading ? (
                  <Loading loading={this.props.loading} />
                ) : !this.props.loading && this.props.newWbs.length ? (
                  <SuppilerReport
                    showFilter={this.state.showFilter}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    loading={this.props.loading}
                    newWbs={this.props.newWbs}
                    ReportFormat={this.state.ReportFormat}
                    handleInputChange={this.handleInputChange}
                  />
                ) : (
                  <Typography
                    variant="h4"
                    color="textSecondary"
                    style={{ marginTop: "2em", textAlign: "center" }}
                  >
                    Select Filter To Generate Report!!
                  </Typography>
                )}
              </div>
            );
            case 9:
              return (
                <div className="container">
                  {this.shownotifications()}
                  <Grid container justify="space-around">
                    <Grid item xs={12}>
                      <Button
                         onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                        className={Style.generatePDF}
                        startIcon={<ArrowBack />}
                        color="secondary"
                      />
                    </Grid>
                    <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                      <Button
                        xs={12}
                        onClick={this.handleFilter}
                        className={Style.generatePDF}
                        startIcon={<FilterList />}
                      >
                        Filter
                      </Button>
                    </Grid>
                    <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                      <Button
                        xs={12}
                        onClick={() => {
                          downloadPDF("Store Report", "p");
                          this.setState({
                            openDownloadStarted: true,
                          });
                        }}
                        className={Style.generatePDF}
                        endIcon={<CloudDownload />}
                      >
                        Export Report
                      </Button>
                    </Grid>
                  </Grid>
                  {this.props.loading ? (
                    <Loading loading={this.props.loading} />
                  ) : !this.props.loading && this.props.newWbs.length ? (
                    <SuppilerReport
                      showFilter={this.state.showFilter}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      loading={this.props.loading}
                      newWbs={this.props.newWbs}
                      ReportFormat={this.state.ReportFormat}
                      handleInputChange={this.handleInputChange}
                    />
                  ) : (
                    <Typography
                      variant="h4"
                      color="textSecondary"
                      style={{ marginTop: "2em", textAlign: "center" }}
                    >
                      Select Filter To Generate Report!!
                    </Typography>
                  )}
                </div>
              );
        case 10:
          return (
            <div className="container">
              {this.shownotifications()}
              <Grid container justify="space-around">
                <Grid item xs={12}>
                  <Button
                     onClick={() => {this.handleShowReport(0);this.clearReportAll()}}
                    className={Style.generatePDF}
                    startIcon={<ArrowBack />}
                    color="secondary"
                  />
                </Grid>
                <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Button
                    xs={12}
                    onClick={this.handleFilter}
                    className={Style.generatePDF}
                    startIcon={<FilterList />}
                  >
                    Filter
                  </Button>
                </Grid>
                <Grid xs={12} sm={6} style={{ textAlign: "center" }}>
                  <Button
                    xs={12}
                    onClick={() => {
                      downloadPDF("Store Report", "p");
                      this.setState({
                        openDownloadStarted: true,
                      });
                    }}
                    className={Style.generatePDF}
                    endIcon={<CloudDownload />}
                  >
                    Export Report
                  </Button>
                </Grid>
              </Grid>
              {this.props.loading ? (
                <Loading loading={this.props.loading} />
              ) : !this.props.loading && this.props.newWbs.length ? (
                <StoreReport
                  showFilter={this.state.showFilter}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  loading={this.props.loading}
                  newWbs={this.props.newWbs}
                  handleInputChange={this.handleInputChange}
                />
              ) : (
                <Typography
                  variant="h4"
                  color="textSecondary"
                  style={{ marginTop: "2em", textAlign: "center" }}
                >
                  Select Filter To Generate Report!!
                </Typography>
              )}
            </div>
          );
      default:
        return "Unknown Report";
    }
  }

  render() {
  
        return this.state.showScreen ? (
          <>{this.getStepContent()}</>
        ) : (
          !this.state.showScreen && (
            <div className="container">
              <List
                className={Style.container___info}
                style={{ marginTop: "30px" }}
              >
                {this.Reports.map((label, i) => (
                  <ListItem
                    className={Style.ListItem}
                    onClick={() => this.handleShowReport(i)}
                  >
                    <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
                      {label}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </div>
          )
        );
 
  }
}

const mapStateToProps = state => {
  return {
    loading: state.wbsReducer.loading,
    newWbs: state.wbsReducer.newWbs,
  };
};

export default connect(mapStateToProps, {
  getWbs,
  clearReport,
  getDPRWbs,
  getMaterialsWbs,
  getLaboursWbs,
  getcontractorRaWbs,
  getSupplierWbs,
  getStoreWbs
})(Wbs);
