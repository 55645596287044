import React from "react";
import {
  ShoppingCart,
  Add as AddIcon,
  LocalActivity,
  Receipt,
  Work,
  BugReport,
  List as ListIcon,
  PersonalVideo as Monitor,
  BarChart as BarChartIcon,
  PlaylistAdd as ToDoIcon,
  Store as StoreIcon,
  EventAvailable as Schedule,
  PlaylistAddCheck as Delivery,
  Person,
  Help as HelpIcon,
  ErrorOutline,
  EmojiObjectsOutlined,
  GroupWorkOutlined,
  TimelineOutlined,
  VerifiedUserOutlined,
  Dashboard,
  DonutLarge,
  OndemandVideoOutlined,
  ContactMail,
  LibraryBooks,
} from "@material-ui/icons";

import GavelIcon from "@material-ui/icons/Gavel";
import BuildIcon from "@material-ui/icons/Build";
import PeopleIcon from "@material-ui/icons/People";

const rolesWithQualityDrawerVisible = ["quality_engineer"];
const rolesWithSafetyDrawerVisible = ["safety_engineer"];
const rolesWithPlanningDrawerVisible = ["planning_manager"];
const rolesWithEditUserVisible = [
  "super_admin",
];

export const drawerDataUtil = role => {
  if (window.location.href.split("/")[3] === "") {
    return homepageDrawerItems;
  }
  if (global.config.secureStorage.getItem("main_admin")) {
    return [dashboard, ...mainDrawer, editUsers,];
  }
  if (global.config.rolesWithStoreDrawerVisible.find(item => item === role)) {
    return [...TopGlobalItems, ...storeDrawer, ...BottomGlobalItems];
  }
  if (rolesWithQualityDrawerVisible.find(item => item == role)) {
    return [...TopGlobalItems, ...qualityItems, todo, ...BottomGlobalItems];
  }
  if (rolesWithSafetyDrawerVisible.find(item => item == role)) {
    return [...TopGlobalItems, ...safetyItems, todo, ...BottomGlobalItems];
  }
  if (rolesWithPlanningDrawerVisible.find(item => item == role)) {
    return [...TopGlobalItems, ...planningItems, ...BottomGlobalItems];
  }
  if (rolesWithEditUserVisible.find(item => item == role)) {
    return [...mainDrawer, editUsers];
  }
  return mainDrawer;
};

const todo = { name: "To Do", route: "/todo", icon: <ToDoIcon /> };
const dashboard = {
  name: "Dashboard",
  route: "/dashboard",
  icon: <Dashboard />,
};
const editUsers = { name: "Edit Users", icon: <Person />, route: "/editUser" };
const addMaterial = {
  name: "Add Material",
  route: "/addMaterial",
  icon: <AddIcon />,
};

const equipmentsItems = [
  { name: "Fuel Consumption", route: "/store", icon: <Schedule /> },
];

const TopGlobalItems = [
  { name: "Overview", route: "/overview", icon: <LocalActivity /> },
  { name: "Activities", route: "/activities", icon: <LocalActivity /> },
]
const BottomGlobalItems = [
  { name: "Projects", route: "/projectList", icon: <Work /> },
  { name: "Support", route: "/support", icon: <HelpIcon /> },
]



const planningItems = [

  // { name: "Bar Chart", route: "/barchart", icon: <BarChartIcon /> },
  { name: "Monitoring", route: "/monitoring", icon: <Monitor /> },
  { name: "Daily Progress Report", route: "/dpr", icon: <DonutLarge /> },

];
const misItems = [
  { name: "WBS report", route: "/wbs", icon: <Schedule /> },
  { name: "Quality Report", route: "/activities", icon: <BarChartIcon /> },
  { name: "Safety Report ", route: "/activities", icon: <Monitor /> },
  {
    name: "Actually vs Estimated report ",
    route: "/activities",
    icon: <Delivery />,
  },
  { name: "Stock Report ", route: "/activities", icon: <DonutLarge /> },
  {
    name: "Project Summary Report",
    route: "/activities",
    icon: <Delivery />,
  },
];

const libraryItems = [
  {
    name: "Contractor's RA",
    route: "/labour",
    icon: <PeopleIcon />,
  },
  { name: "Material", route: "/materials", icon: <BuildIcon /> },
  { name: "Equipment", route: "/equipment", icon: <GavelIcon /> },
];
const MasterListItems=[
  { name:"Material", route:"/addMaterial",icon:<AddIcon/>},
{name:"Contractor's RA",route:"/addContractorRA",icon:<AddIcon/>},
{name:"Equipment",route:"/addEquipment",icon:<AddIcon/>},
{name:"Manpower",route:"/addManpower",icon:<AddIcon/>}
]
const ss = [
  { name: "GRN ", route: "/store", icon: <Delivery /> },
  { name: "Issue request material ", route: "/store", icon: <Delivery /> },
  { name: "Issue material  ", route: "/store", icon: <Delivery /> },
  { name: " Daily store Report  ", route: "/store", icon: <Delivery /> },
];


const safetyItems = [
  { name: "Checklist", route: "/checklist/safety", icon: <Receipt /> },
  { name: "Issues", route: "/issues/safety", icon: <BugReport /> },
];

const qualityItems = [
  { name: "Checklist", route: "/checklist/quality", icon: <Receipt /> },
  { name: "Issues", route: "/issues/quality", icon: <BugReport /> },
];

const MasterList={ name: "Master List",icon: <ListIcon />, content: MasterListItems }

const storeDrawer = [
  { name: "Material Requisition", route: "/material", icon: <Delivery /> },
  { name: "Store", route: "/store", icon: <StoreIcon /> },
  { name: "Material Tracking", route: "/material-tracking", icon: <Receipt /> },
  // MasterList
  // { name: "Store", content: ss, icon: <StoreIcon /> },
];
const mainDrawer = [
  { name: "Overview", route: "/overview", icon: <LocalActivity /> },
  { name: "Activities", route: "/activities", icon: <LocalActivity /> },
  { name: "Safety", icon: <ListIcon />, content: safetyItems },
  { name: "Quality", icon: <ListIcon />, content: qualityItems },
  { name: "Purchase", icon: <ShoppingCart />, content: storeDrawer },
  // { name: "Planning", icon: <ListIcon />, content: planningItems },
  // { name: "Equipments", icon: <ListIcon />, content: equipmentsItems },
  // { name: "MIS", icon: <ListIcon />, content: misItems },
  
  // { name: "Library", icon: <ListIcon />, content: libraryItems },
  // { name: "Chat", route: "/activities", icon: <ListIcon /> },
  { name: "Planning", icon: <ListIcon />, content: planningItems },
  { name: "Library", icon: <LibraryBooks />, content: libraryItems },
  todo,
  { name: "MIS", route: "/wbs", icon: <Schedule /> },
  { name: "Projects", route: "/projectList", icon: <Work /> },
  { name: "Support", route: "/support", icon: <HelpIcon /> },
  MasterList
];

const homepageDrawerItems = [
  { name: "About Us", route: "about", icon: <GroupWorkOutlined /> },
  { name: "Problems Faced", route: "problems", icon: <ErrorOutline /> },
  { name: "Our Solution", route: "objective", icon: <EmojiObjectsOutlined /> },
  { name: "Timeline", route: "timeline", icon: <TimelineOutlined /> },
  {
    name: "Features and Services",
    route: "services",
    icon: <VerifiedUserOutlined />,
  },
  {
    name: "Demo",
    route: "demo",
    icon: <OndemandVideoOutlined />,
  },
  {
    name: "Contact Us",
    route: "contact",
    icon: <ContactMail />,
  },

];
